import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { debounce, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import { FeAutocomplete } from "../../FormElements";
import FeTextField from "../../FormElements/FeTextField";
const StatOptions = ["Positive", "Negative", "Not Done"];
const ki67Options = [">14%", "<14%", "Not Done"];
const DCalForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [formValue, setFormValue] = useState({
    er: null,
    pr: null,
    her2: null,
    ki67: null,
    result: null,
  });

  const [errors, setErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);
  function convertEmptyStringToNull(obj) {
    const newObj = {};

    for (let key in obj) {
      newObj[key] = obj[key] === "" ? null : obj[key];
    }

    return newObj;
  }
  async function getDetails(id) {
    const { data } = await adminAPi.get(
      `admin/diagnosis-group-calculation-records/${id}`
    );
    setFormValue(data.data);
  }

  const handleField = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    const updatedData = convertEmptyStringToNull(formValue);
    e.preventDefault();
    try {
      setErrors({});
      setErrorMsg("");
      if (id) {
        console.log("AAAA", updatedData);
        const { data } = await adminAPi.put(
          `admin/diagnosis-group-calculation-records/${id}`,
          updatedData
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      } else {
        console.log("AAAA", updatedData);
        const { data } = await adminAPi.post(
          "admin/diagnosis-group-calculation-records",
          updatedData
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      }
    } catch ({ response }) {
      console.log(response);
      if (response) {
        const { data } = response;
        if (data && data.errors) {
          setErrors(data.errors);
        }

        if (data && data.message) {
          setErrorMsg(data.message);
        }
      }
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={3} md={3}>
            <Box mb={2}>
              <FeAutocomplete
                options={StatOptions}
                value={formValue?.er}
                id="Options"
                label="ER"
                name="er"
                onChange={(e, v) => {
                  // handleChangeInput("gender", v);
                  setFormValue({ ...formValue, er: v });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3} md={3}>
            <Box mb={2}>
              <FeAutocomplete
                options={StatOptions}
                value={formValue.pr}
                id="Options"
                label="PR"
                name="pr"
                onChange={(e, v) => {
                  // handleChangeInput("gender", v);
                  setFormValue({ ...formValue, pr: v });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3} md={3}>
            <Box mb={2}>
              <FeAutocomplete
                options={StatOptions}
                value={formValue.her2}
                id="Options"
                label="HER2"
                name="her2"
                onChange={(e, v) => {
                  // handleChangeInput("gender", v);
                  setFormValue({ ...formValue, her2: v });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3} md={3}>
            <Box mb={2}>
              <FeAutocomplete
                options={ki67Options}
                value={formValue.ki67}
                id="Options"
                label="Ki67"
                name="ki67"
                onChange={(e, v) => {
                  // handleChangeInput("gender", v);
                  setFormValue({ ...formValue, ki67: v });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3} md={3}>
            <Box mb={2}>
              <FeTextField
                name="result"
                id="result"
                label="Result"
                value={formValue.result ? formValue.result : ""}
                onChange={handleField}
              />
            </Box>
          </Grid>
        </Grid>

        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection={"column"}
          mt={2}
        >
          {!isEmpty(errors) && (
            <>
              {Object.keys(errors).map((o, j) => {
                return (
                  <Typography
                    key={j}
                    variant="subtitle2"
                    gutterBottom
                    sx={{ color: "red" }}
                  >
                    {errors[o][0]}
                  </Typography>
                );
              })}
            </>
          )}
          {!isEmpty(errorMsg) && (
            <Typography variant="subtitle2" gutterBottom sx={{ color: "red" }}>
              {errorMsg}
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
export default DCalForm;
