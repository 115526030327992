import React from "react";
import { Navigate } from "react-router-dom";
function ProtectedRouteDoctor({ admin, redirectPath = "/", children }) {
  const USER_INFO = JSON.parse(localStorage.getItem("CANCER_USER_INFO"));
  let auth = null;

  if (USER_INFO && USER_INFO.access_token) {
    const token = USER_INFO.access_token;
    auth = token;
  }
  // admin(auth);
  if (!auth) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
}
export default ProtectedRouteDoctor;
