import React from "react";
import { Link } from "react-router-dom";
import { Box, Container, Grid, Typography, Button } from "@mui/material";

const NotFoundPage = () => {
  return (
    <Box className="page_404">
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box textAlign="center" mt={5}>
              <Box className="four_zero_four_bg">
                <Typography variant="h1" className="text-center">
                  404
                </Typography>
              </Box>
              <Box className="contant_box_404" mt={3}>
                <Typography variant="h3" component="h2">
                  Look like you're lost
                </Typography>
                <Typography variant="body1">
                  The page you are looking for is not available!
                </Typography>
                <Button
                  component={Link}
                  to="/"
                  className="link_404"
                  variant="contained"
                >
                  Go to Home
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NotFoundPage;
