import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import { FeAutocomplete, FeTextField } from "../../../FormElements";
import doctorAPI from "../../../../utils/doctorAPI";

const ChemotherapyProtocolForm = ({ onStateChange, editedObj, updatedObj }) => {
  const [rows, setRows] = useState([]);

  const [protocols, setProtocols] = useState([
    {
      cycle_no: null,
      type: "primary",
      chemotherapy_protocol_details: [{ value: "" }],
    },
  ]);

  useEffect(() => {
    const mergedObj = {
      chemotherapy_protocols: protocols,
    };

    onStateChange(mergedObj);
  }, [protocols]);
  useEffect(() => {
    if (updatedObj && updatedObj.chemotherapy_protocols) {
      setProtocols(updatedObj.chemotherapy_protocols);
      return;
    }
    if (editedObj && editedObj.chemotherapy_protocols) {
      setProtocols(editedObj.chemotherapy_protocols);
      return;
    }
  }, [updatedObj, editedObj]);
  // Function to handle adding new protocol either primary or followed_by
  const handleAddProtocol = (type) => {
    setProtocols([
      ...protocols,
      { cycle_no: null, type, chemotherapy_protocol_details: [{ value: "" }] },
    ]);
  };

  const handleRemoveProtocolDetail = (protocolIndex, detailIndex) => {
    const updated = [...protocols];
    const protocolDetails =
      updated[protocolIndex].chemotherapy_protocol_details;

    if (protocolDetails.length > 1) {
      // Remove only the specific detail
      protocolDetails.splice(detailIndex, 1);
      updated[protocolIndex].chemotherapy_protocol_details = protocolDetails;
    } else {
      // If it's the last detail, clear the value and also reset the cycle_no
      updated.splice(protocolIndex, 1);
    }

    setProtocols(updated);
  };

  const handleChange = (index, key, value) => {
    const updated = [...protocols];
    updated[index][key] = value;
    setProtocols(updated);
  };

  const handleProtocolChange = (index, detailIndex, value) => {
    const updated = [...protocols];
    updated[index].chemotherapy_protocol_details[detailIndex].value = value;
    setProtocols(updated);
  };

  const addDiagnosisField = (parentIndex) => {
    const updated = [...protocols];
    updated[parentIndex].chemotherapy_protocol_details.push({ value: "" });
    setProtocols(updated);
  };

  const protocolSummary = protocols
    .map(
      (protocol, index) =>
        ` ${
          protocol.type === "followed_by" ? "Followed by" : ""
        } ${protocol.chemotherapy_protocol_details
          .map((detail) => detail.value)
          .join(" + ")} ${protocol.cycle_no ?? ""} ${
          protocol.cycle_no ? "Cycle" : ""
        }`
    )
    .join(", ");

  useEffect(() => {
    getList();

    return () => {};
  }, []);

  const getList = async () => {
    try {
      const { data } = await doctorAPI.get(
        "doctor/chemotherapy-protocol-records-all"
      );
      if (data) {
        setRows(data.data);
      }
    } catch (error) {}
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box mb={2}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={1}>
              <Grid mb={1} item sm={4}>
                Treatment Protocol
              </Grid>
            </Grid>
            {protocols.length === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Button
                    onClick={() => handleAddProtocol("primary")}
                    variant="outlined"
                    color="success"
                    //  disabled={editedObj}
                  >
                    Add Chemoprotocol{" "}
                  </Button>
                </Grid>
              </Grid>
            )}

            {protocols.map((protocol, index) => {
              const { chemotherapy_protocol_details = [] } = protocol;
              const { cycle_no, type } = protocol;

              return (
                <>
                  {chemotherapy_protocol_details.length > 0 &&
                    chemotherapy_protocol_details.map((data, detailIndex) => {
                      const { value } = data;

                      return (
                        <Grid container spacing={2} key={detailIndex}>
                          {rows.length > 0 && (
                            <Grid item xs={6} md={6} mb={1}>
                              <FeAutocomplete
                                options={rows.map((o) => o.name)}
                                id="LOT"
                                value={value}
                                onChange={(e, v) => {
                                  handleProtocolChange(index, detailIndex, v);
                                }}
                              />
                            </Grid>
                          )}
                          {chemotherapy_protocol_details.length - 1 ===
                            detailIndex && (
                            <>
                              <Grid item xs={1} md={1} mb={1}>
                                <IconButton
                                  aria-label="add"
                                  size="large"
                                  color="success"
                                  onClick={() => addDiagnosisField(index)}
                                >
                                  <AddIcon fontSize="inherit" />
                                </IconButton>
                              </Grid>{" "}
                              <Grid item xs={2} md={2} mb={1}>
                                <FeTextField
                                  type="number"
                                  value={cycle_no}
                                  onChange={(e, v) => {
                                    handleChange(
                                      index,
                                      "cycle_no",
                                      e.target.value
                                    );
                                  }}
                                  label="Cycle No"
                                />{" "}
                              </Grid>
                            </>
                          )}
                          <></>

                          <Grid item xs={1}>
                            <IconButton
                              aria-label="delete"
                              size="large"
                              color="error"
                              onClick={() =>
                                handleRemoveProtocolDetail(index, detailIndex)
                              }
                            >
                              <DeleteForeverIcon fontSize="inherit" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    })}
                </>
              );
            })}

            {/* Followed By Button, always stays at the bottom */}
            {protocols.length > 0 && (
              <Grid item xs={12} md={12} mb={0}>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => handleAddProtocol("followed_by")}
                  style={{ marginTop: "10px" }}
                >
                  Followed By
                </Button>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.23)",
                padding: "15px",
                marginTop: "1rem",
              }}
            >
              <Typography variant="h6">{protocolSummary}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default ChemotherapyProtocolForm;
