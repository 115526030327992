import { Box, Button, DialogActions, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ChemoModDetails from "../../../Doctor/PatientObservations/Breast/ChemoModDetails";
import ChemoProtoDateCycle from "../../../Doctor/PatientObservations/Breast/ChemoProtoDateCycle";
import ChemotherapyProtocolForm from "../../../Doctor/PatientObservations/Breast/ChemotherapyProtocolForm";
import ChemoDetails from "../../../Doctor/PatientObservations/Breast/ChemDetails";
import ClinicalResponseRate from "../../../Doctor/PatientObservations/Breast/ClinicalResponseRate";
import RadiologicalResponseRate from "../../../Doctor/PatientObservations/Breast/RadiologicalResponseRate";
import PathologicalResponseRate from "../../../Doctor/PatientObservations/Breast/PathologicalResponseRate";
import StatForm from "../../../Doctor/PatientObservations/Breast/StatForm";
import { merge } from "lodash";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
const ChemoCycle = ({
  first_diagnosis_date,
  setShowChemo,
  setChemoArray,
  updatedChemo,
  editing,
  prevSavedObj,
}) => {
  const [currentObjToEdit, setCurrentObjToEdit] = useState(null);
  const [editedObj, setEditedObj] = useState(null);
  const ChemoModDetailsRef = useRef(null);
  const ChemotherapyProtocolRef = useRef(null);
  const ChemoDetailsRef = useRef(null);
  const ChemoProtoDateCycleRef = useRef(null);
  const StatFormRef = useRef(null);
  const ClinicalResRateRef = useRef(null);
  const RadioResRateRef = useRef(null);
  const PathoResRateRef = useRef(null);

  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    if (
      editing &&
      updatedChemo &&
      updatedChemo.patient_observation_details &&
      updatedChemo.patient_observation_details.length > 0
    ) {
      setCurrentObjToEdit(null);
      resetRefValues();
      setTimeout(() => {
        setCurrentObjToEdit(
          updatedChemo.patient_observation_details[value - 1]
        );
      }, 100);
    } else if (
      editing &&
      !updatedChemo &&
      prevSavedObj &&
      prevSavedObj.patient_observation_details &&
      prevSavedObj.patient_observation_details.length > 0
    ) {
      setCurrentObjToEdit(null);
      setEditedObj(null);
      resetRefValues();
      setTimeout(() => {
        setEditedObj(prevSavedObj.patient_observation_details[value - 1]);
      }, 100);
    }
  };
  const resetRefValues = () => {
    ChemoModDetailsRef.current = null;
    ChemoProtoDateCycleRef.current = null;
    ChemoDetailsRef.current = null;
    ChemotherapyProtocolRef.current = null;
    StatFormRef.current = null;
    ClinicalResRateRef.current = null;
    RadioResRateRef.current = null;
    PathoResRateRef.current = null;
  };
  useEffect(() => {
    if (
      editing &&
      updatedChemo &&
      updatedChemo.patient_observation_details &&
      updatedChemo.patient_observation_details.length > 0
    ) {
      setPage(updatedChemo.patient_observation_details.length);
      setTotal(updatedChemo.patient_observation_details.length);

      setCurrentObjToEdit(null);
      resetRefValues();
      setTimeout(() => {
        setCurrentObjToEdit(
          updatedChemo.patient_observation_details[
            updatedChemo.patient_observation_details.length - 1
          ]
        );
      }, 100);
    } else if (
      editing &&
      !updatedChemo &&
      prevSavedObj &&
      prevSavedObj.patient_observation_details &&
      prevSavedObj.patient_observation_details.length > 0
    ) {
      setPage(prevSavedObj.patient_observation_details.length);
      setTotal(prevSavedObj.patient_observation_details.length);
      setEditedObj(null);
      resetRefValues();
      setTimeout(() => {
        setEditedObj(
          prevSavedObj.patient_observation_details[
            prevSavedObj.patient_observation_details.length - 1
          ]
        );
      }, 100);
    }

    return () => {};
  }, [updatedChemo, editing, prevSavedObj]);
  // useEffect(() => {
  //   if (editing && updatedChemo && updatedChemo.length > 0) {
  //     setPage(updatedChemo.length);
  //     setCurrentObjToEdit(null);
  //     resetRefValues();
  //     setTimeout(() => {
  //       setCurrentObjToEdit(updatedChemo[updatedChemo.length - 1]);
  //     }, 100);
  //   }

  //   return () => {};
  // }, [savedObj]);

  return (
    <>
      {editing &&
        ((updatedChemo &&
          updatedChemo.patient_observation_details &&
          updatedChemo.patient_observation_details.length > 0) ||
          (prevSavedObj &&
            prevSavedObj.patient_observation_details &&
            prevSavedObj.patient_observation_details.length > 0)) && (
          <Stack spacing={2}>
            <Typography>Cycle: {page}</Typography>
            <Pagination count={total} page={page} onChange={handleChange} />
          </Stack>
        )}
      <Grid container spacing={1}>
        <ChemoModDetails
          editedObj={editedObj}
          onStateChange={(state) => {
            ChemoModDetailsRef.current = state;
          }}
          updatedObj={currentObjToEdit}
        />
        <ChemoProtoDateCycle
          editedObj={editedObj}
          onStateChange={(state) => {
            ChemoProtoDateCycleRef.current = state;
          }}
          updatedObj={currentObjToEdit}
        />
        <ChemotherapyProtocolForm
          editedObj={editedObj}
          onStateChange={(state) => {
            ChemotherapyProtocolRef.current = state;
          }}
          updatedObj={currentObjToEdit}
        />{" "}
        <ChemoDetails
          editedObj={editedObj}
          onStateChange={(state) => {
            ChemoDetailsRef.current = state;
          }}
          updatedObj={currentObjToEdit}
        />
        <Grid item xs={6} md={4}></Grid>
        <ClinicalResponseRate
          editedObj={editedObj}
          onStateChange={(state) => {
            ClinicalResRateRef.current = state;
          }}
          updatedObj={currentObjToEdit}
        />
        <RadiologicalResponseRate
          editedObj={editedObj}
          onStateChange={(state) => {
            RadioResRateRef.current = state;
          }}
          updatedObj={currentObjToEdit}
        />
        <PathologicalResponseRate
          editedObj={editedObj}
          onStateChange={(state) => {
            PathoResRateRef.current = state;
          }}
          updatedObj={currentObjToEdit}
        />
        <StatForm
          editedObj={editedObj}
          diagDate={first_diagnosis_date}
          onStateChange={(state) => {
            StatFormRef.current = state;
          }}
          updatedObj={currentObjToEdit}
        />
      </Grid>
      <Box
        sx={{
          position: "sticky",
          left: 0,
          bottom: 0,
          backgroundColor: "background.paper",
          borderTop: "1px solid",
          borderColor: "divider",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          zIndex: 1000,
          gap: "10px",
          marginTop: "auto",
          padding: "10px",
        }}
        padding={"0px 20px"}
      >
        {" "}
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1.5}
          width={"100%"}
        >
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="success"
            onClick={() => {
              let objtoPost1 = merge(
                ChemoModDetailsRef.current,
                ChemoProtoDateCycleRef.current,
                ChemoDetailsRef.current,
                ChemotherapyProtocolRef.current,
                StatFormRef.current
              );

              let objtoPost = {
                ...objtoPost1,

                ...ClinicalResRateRef.current,
                ...RadioResRateRef.current,
                ...PathoResRateRef.current,
              };

              let prevArr =
                updatedChemo &&
                updatedChemo.patient_observation_details &&
                updatedChemo.patient_observation_details.length > 0
                  ? updatedChemo.patient_observation_details
                  : [];
              if (
                !updatedChemo &&
                prevSavedObj &&
                prevSavedObj.patient_observation_details &&
                prevSavedObj.patient_observation_details.length > 0
              ) {
                prevArr = prevSavedObj.patient_observation_details;
              }
              if (
                editing &&
                ((updatedChemo &&
                  updatedChemo.patient_observation_details &&
                  updatedChemo.patient_observation_details.length > 0) ||
                  (!updatedChemo &&
                    prevSavedObj &&
                    prevSavedObj.patient_observation_details &&
                    prevSavedObj.patient_observation_details.length > 0))
              ) {
                prevArr[page - 1] = objtoPost;
              } else {
                prevArr.push(objtoPost);
              }
              const obj = {
                patient_observation_details: prevArr,
              };
              resetRefValues();
              setChemoArray(obj);
              setShowChemo(false);
            }}
          >
            Submit
          </Button>
        </Box>{" "}
      </Box>
    </>
  );
};

export default ChemoCycle;
