import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Alert,
  Button,
  Collapse,
  IconButton,
  TextField,
  Typography,
  Grid,
  Box,
  Container,
} from "@mui/material";

import { FeTextField } from "../../components/FormElements";
import Logo from "../../images/logo.png";
import LoginBg from "../../images/login__bg.jpg";
import axios from "axios";
import { useFormik } from "formik";
const baseURL = process.env.REACT_APP_BASE_URL;
const AdminForget = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errmsg, setErrmsg] = useState("");
  const [showPostOTP, setShowPostOTP] = React.useState(false);
  const [countDown, setCountDown] = React.useState("");

  const [errorMessage, setErrorMessage] = useState({});

  const validationSchemaBeforeCode = Yup.object({
    email: Yup.string()
      .required("Please enter your email number")
      .typeError("This field is required"),
  });

  const validationSchemaAfterCode = Yup.object({
    email: Yup.string()
      .required("Please enter your email number")
      .typeError("This field is required"),

    token: Yup.string().required("Code field is required"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match"),
  });

  const initialValues = {
    email: "",
    token: "",
    password: "",
    confirm_password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema:
      code === "" ? validationSchemaBeforeCode : validationSchemaAfterCode,
    onSubmit: (values) => handleSubmit(values),
  });

  const postOTPAgain = (values) => {
    setCode("");
    setShowPostOTP(false);
    handlePostOTP(values);
  };

  const handlePostOTP = (values) => {
    axios
      .post(`${baseURL}admin/auth/forgot-password-request`, {
        email: values.email,
      })
      .then((response) => {
        setCode(response.data.message);
        setErrmsg("");
        setErrorMessage({ email: "" });

        var timeLeft = 30;
        var timerId = setInterval(countdown, 1000);

        function countdown() {
          if (timeLeft === -1) {
            clearTimeout(timerId);
            setShowPostOTP(true);
          } else {
            setCountDown(timeLeft);
            timeLeft--;
          }
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message) setErrmsg(error.response.data.message);
        if (error.response.data.errors !== undefined) {
          setErrorMessage(error.response.data.errors);
        }
      });
  };

  const handleSubmit = (values) => {
    setLoading(true);
    if (code === "") {
      postOTPAgain(values);
    } else {
      axios
        .post(`${baseURL}admin/auth/forgot-password`, values)
        .then((response) => {
          setErrmsg("");
          setSuccessMessage(response.data.message);
          setLoading(false);
          setTimeout(() => {
            // navigate("/admin/login");
          }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.data.message)
            setErrmsg(error.response.data.message);
          if (error.response.data.errors !== undefined) {
            setErrorMessage(error.response.data.errors);
          }
        });
    }
  };
  return (
    <Box className="Login__page" height="100vh" display="flex">
      <Box
        height="100%"
        pt={2}
        px={4}
        width="100%"
        maxWidth="800px"
        display="flex"
        flexDirection="column"
      >
        <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <img src={Logo}  width="150"/>
        </Box>
        <Box flexGrow={1} py={3} display="flex" alignItems="center">
          <Box width="100%" noValidate>
            <Box mb={3}>
              <Typography component="h1" variant="h4">
                {/* Admin Login */}
              </Typography>
            </Box>
            <Container>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box p={3} sx={{ border: "1px solid #ccc", borderRadius: 4 }}>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Email"
                            type="text"
                            name="email"
                            placeholder="Your Email"
                            {...formik.getFieldProps("email")}
                          />
                          {code === "" && (
                            <Typography variant="caption" color="textSecondary">
                              Please enter your registered email to get an OTP
                              code via email
                            </Typography>
                          )}
                          {code !== "" && (
                            <>
                              <Typography style={{ marginBottom: 0 }}>
                                {code}
                              </Typography>
                              <Box mt="-0.25px" textAlign="right">
                                <Button
                                  onClick={() => postOTPAgain(formik.values)}
                                  type="button"
                                  disabled={!showPostOTP}
                                  variant="contained"
                                  size="small"
                                >
                                  Sent OTP Again{" "}
                                  {countDown === 0 ? "" : `( ${countDown} )`}
                                </Button>
                              </Box>
                            </>
                          )}
                          <Typography sx={{ color: "red", fontSize: "12px" }}>
                            {formik.touched.email && formik.errors.email}
                          </Typography>
                        </Grid>{" "}
                        {code === "" ? (
                          <>
                            {" "}
                            <Grid item xs={12}>
                              <Button
                                disabled={loading}
                                type="submit"
                                variant="contained"
                                color="success"
                                fullWidth
                              >
                                {loading && (
                                  <i className="fa fa-refresh fa-spin"></i>
                                )}
                                Submit
                              </Button>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Code"
                                type="text"
                                name="token"
                                placeholder="Enter Your OTP here"
                                {...formik.getFieldProps("token")}
                              />
                              <Typography
                                sx={{ color: "red", fontSize: "12px" }}
                              >
                                {formik.touched.token && formik.errors.token}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Password"
                                type="password"
                                name="password"
                                placeholder="password"
                                {...formik.getFieldProps("password")}
                              />
                              <Typography
                                sx={{ color: "red", fontSize: "12px" }}
                              >
                                {formik.touched.password &&
                                  formik.errors.password}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Confirm Password"
                                type="password"
                                name="confirm_password"
                                placeholder="password"
                                {...formik.getFieldProps("confirm_password")}
                              />
                              <Typography
                                sx={{ color: "red", fontSize: "12px" }}
                              >
                                {formik.touched.confirm_password &&
                                  formik.errors.confirm_password}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                sx={{ color: "red", fontSize: "12px" }}
                              >
                                {errmsg}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Collapse in={successMessage !== ""}>
                                <Alert severity="success">
                                  {successMessage}
                                </Alert>
                              </Collapse>
                              <Collapse in={errmsg !== ""}>
                                <Alert severity="error">{errmsg}</Alert>
                              </Collapse>
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                disabled={loading}
                                type="submit"
                                variant="contained"
                                color="success"
                                fullWidth
                              >
                                {loading && (
                                  <i className="fa fa-refresh fa-spin"></i>
                                )}
                                Submit
                              </Button>
                            </Grid>
                            {successMessage !== "" && (
                              <Grid item xs={12}>
                                <Typography>
                                  {successMessage}. Please press Login below to
                                  continue with your updated password
                                </Typography>
                                <Link
                                  to="/admin/login"
                                  component={Button}
                                  variant="contained"
                                  fullWidth
                                >
                                  Login
                                </Link>
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                    </form>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Box textAlign="center" pb={2}>
          <Typography variant="caption">
            Copyright &copy; 2023. All Rights Reserved.{" "}
          </Typography>
        </Box>
      </Box>
      <Box
        height="100%"
        flexGrow={1}
        sx={{
          backgroundImage: `url(${LoginBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "relative",
          "&:before": {
            content: '""',
            display: "block",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            background:
              "radial-gradient(100% 225% at 100% 0%, #FF0000 0%, #000000 100%), linear-gradient(236deg, #00C2FF 0%, #000000 100%), linear-gradient(135deg, #CDFFEB 0%, #CDFFEB 36%, #009F9D 36%, #009F9D 60%, #07456F 60%, #07456F 67%, #0F0A3C 67%, #0F0A3C 100%)",
            backgroundBlendMode: "overlay, hard-light, normal;",
            opacity: 0.8,
          },
        }}
      ></Box>
    </Box>
  );
};

export default AdminForget;
