import React, { useEffect } from "react";
import Plot from "react-plotly.js";
import Stack from "@mui/material/Stack";
import { FeAutocomplete } from "../../FormElements";
import { Box, Grid } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import FeDesktopDatePicker from "../../FormElements/FeDesktopDatePicker";
import adminAPi from "../../../utils/adminAPi";
import { useState } from "react";
import { isEmpty } from "lodash";

var x = [];
const options = [
  "date",
  "radiotherapySchedules",
  "laterality",
  "grade",
  "current_chemo_protocol",
  "chemo_cycle_no",
  "chemo_detail",
  "pastTreatmentHistories",
  "comorbidities",
  "histopathologies",
  "stagingClinicals",
  "stagingPathologicals",
  "chemotherapyModalities",
  "chemo_starting_date",
  "stagingPathologicalDetails_lvsi",
  "stagingPathologicalDetails_pni",
  "stagingPathologicalDetails_margin",
  "stagingPathologicalDetails_ki67",
  "chemotherapyProtocols",
  "surgery",
];
for (var i = 0; i < 500; i++) {
  x[i] = Math.random();
}

const Charts = () => {
  const [params, setParams] = useState({
    x: "",
    y: "",
    from: null,
    to: null,
  });
  const [paramsS, setParamsS] = useState({
    key: "",
    from: null,
    to: null,
  });
  const [plotData, setPlotData] = useState({});
  const [layout, setLayout] = useState({});
  const [plotDataS, setPlotDataS] = useState({});
  const [layoutS, setLayoutS] = useState({});
  const [sList, setSList] = useState([]);
  const [type, setType] = useState("Line");
  const [moleCularList, setMoleCularList] = useState([]);
  const [exonList, setExonList] = useState([]);
  useEffect(() => {
    if (!isEmpty(paramsS.key)) {
      getChartsS(paramsS);
    }

    return () => {};
  }, [paramsS]);

  useEffect(() => {
    getMolecularList();
    getExonRecordList();
  }, []);
  const getExonRecordList = async () => {
    try {
      const { data } = await adminAPi.get("admin/exon-records", params);
      setExonList(data.data.data);
    } catch (error) {
      console.log("EE", error.response);
    }
  };
  const getMolecularList = async () => {
    try {
      const { data } = await adminAPi.get(
        "admin/molecular-pathology-records-all"
      );
      console.log("SSS", data);
      setMoleCularList(data.data);
    } catch (error) {
      console.log("EE", error.response);
    }
  };

  const getChartsS = async (params, type) => {
    let ptoPass = { ...params };
    // if (!ptoPass.from || !ptoPass.to) {
    //   delete ptoPass.from;
    //   delete ptoPass.to;
    // }
    const { data } = await adminAPi.post(`admin/single-chart`, ptoPass);
    console.log("DDDD", data);
    let values = data.data.map((o) => o.count);
    let labels = data.data.map((o) => o.value);
    setPlotDataS({
      values,
      labels,
      type: type === "Line" ? "pie" : "pie",
      textposition: "outside",

      automargin: true,
    });
    setLayout({
      xaxis: {
        title: {
          font: {
            family: "Courier New, monospace",

            size: 18,

            color: "#7f7f7f",
          },
        },
      },

      yaxis: {
        title: {
          font: {
            family: "Courier New, monospace",

            size: 18,

            color: "#7f7f7f",
          },
        },
      },
    });
    // console.log("DATE", x);
  };
  useEffect(() => {
    if (!isEmpty(params.x) && !isEmpty(params.y)) {
      getCharts(params, type);
    }

    return () => {};
  }, [params, type]);

  const getCharts = async (params, type) => {
    let ptoPass = { ...params };
    // if (!ptoPass.from || !ptoPass.to) {
    //   delete ptoPass.from;
    //   delete ptoPass.to;
    // }
    const { data } = await adminAPi.get(`admin/chart`, ptoPass);
    let x = data.data.values.map((o) => o.x);
    let y = data.data.values.map((o) => o.y);
    setPlotData({
      x,
      y,
      type: type === "Line" ? "scatter" : "bar",
    });
    setLayout({
      xaxis: {
        title: {
          text: params.x,

          font: {
            family: "Courier New, monospace",

            size: 18,

            color: "#7f7f7f",
          },
        },
      },

      yaxis: {
        title: {
          text: params.y,

          font: {
            family: "Courier New, monospace",

            size: 18,

            color: "#7f7f7f",
          },
        },
      },
    });
    console.log("DATE", x);
  };

  useEffect(() => {
    getList();

    return () => {};
  }, []);

  console.log("SSS", moleCularList, [
    ...(moleCularList.length > 0 ? moleCularList.map((o) => o.name) : []),
  ]);
  async function getList(id) {
    const { data } = await adminAPi.get(`admin/single-chart-sources`);
    const pairDataArray = Object.entries(data.pairData).map(([key, value]) => ({
      key,
      value,
    }));
    const plainDataArray = data.plainData.map((value, index) => ({
      key: value,
      value,
    }));
    const mergedArray = Array.from(
      new Set(pairDataArray.concat(plainDataArray).map(JSON.stringify))
    ).map(JSON.parse);

    setSList(mergedArray);
  }
  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <FeAutocomplete
            label="Select Key For Single Chart"
            //   value={.}
            options={sList}
            // value={obj.socio_economic_status}
            getOptionLabel={(option) => {
              return option.key || "";
            }}
            onChange={(event, v) => {
              setParamsS({ ...paramsS, key: v.value });
              // handleField(e);
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item sm={8}>
            <FeDesktopDatePicker
              label="From"
              inputFormat="DD/MM/YYYY"
              value={paramsS.from}
              onChange={(val) => {
                if (val)
                  setParamsS({
                    ...paramsS,
                    from: dayjs(val).format("YYYY-MM-DD"),
                  });
                else {
                  setParamsS({
                    ...paramsS,
                    from: null,
                  });
                }

                //   "chemo_starting_date",
                //   dayjs(val).format("YYYY-MM-DD")
                // );
              }}
            />
            <FeDesktopDatePicker
              label="To"
              inputFormat="DD/MM/YYYY"
              value={paramsS.to}
              onChange={(val) => {
                if (val)
                  setParamsS({
                    ...paramsS,
                    to: dayjs(val).format("YYYY-MM-DD"),
                  });
                else {
                  setParamsS({
                    ...paramsS,
                    to: null,
                  });
                }

                // handleChangeInput(
                //   "chemo_starting_date",
                //   dayjs(val).format("YYYY-MM-DD")
                // );
              }}
            />
          </Grid>
        </LocalizationProvider>
      </Grid>
      <Box sx={{ minHeight: "40vh" }}>
        <Plot
          data={[plotDataS]}
          useResizeHandler={true}
          style={{ width: "100%", height: "100%" }}
          config={{ responsive: true }}
          layout={layoutS}
        />
      </Box>
      <Grid container spacing={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item sm={6}>
            <FeAutocomplete
              label="Select X"
              //   value={.}
              options={[
                ...options,
                ...(moleCularList.length > 0
                  ? moleCularList.map((o) => o.name)
                  : []),
                ...(exonList.length > 0 ? exonList.map((o) => o.value) : []),
              ]}
              onChange={(event, v) => {
                setParams({ ...params, x: v });
                // handleField(e);
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <FeAutocomplete
              name="group"
              id="group"
              label="Select Y"
              //   value={.}
              options={[
                ...options,
                ...(moleCularList.length > 0
                  ? moleCularList.map((o) => o.name)
                  : []),
                ...(exonList.length > 0 ? exonList.map((o) => o.value) : []),
              ]}
              onChange={(event, v) => {
                setParams({ ...params, y: v });
                // handleField(e);
              }}
            />
          </Grid>
          <Grid item sm={8}>
            <FeDesktopDatePicker
              label="From"
              clearable
              inputFormat="DD/MM/YYYY"
              value={params.from}
              onChange={(val) => {
                if (val)
                  setParams({
                    ...params,
                    from: dayjs(val).format("YYYY-MM-DD"),
                  });
                else {
                  setParams({
                    ...params,
                    from: null,
                  });
                }
              }}
            />
            <FeDesktopDatePicker
              label="To"
              inputFormat="DD/MM/YYYY"
              value={params.to}
              onChange={(val) => {
                if (val)
                  setParams({
                    ...params,
                    to: dayjs(val).format("YYYY-MM-DD"),
                  });
                else {
                  setParams({
                    ...params,
                    to: null,
                  });
                }
                // handleChangeInput(
                //   "chemo_starting_date",
                //   dayjs(val).format("YYYY-MM-DD")
                // );
              }}
            />
          </Grid>
        </LocalizationProvider>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={4} mt={2}>
          <FeAutocomplete
            label="Chart Type"
            value={type}
            options={["Line", "Bar"]}
            onChange={(event, v) => {
              setType(v);
              // handleField(e);
            }}
          />
        </Grid>
      </Grid>
      <Plot
        data={[plotData]}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%" }}
        config={{ responsive: true }}
        layout={layout}
      />
    </>
  );
};

export default Charts;
