import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Button, Grid, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import adminAPi from "../../../../utils/adminAPi";
import { FeAutocomplete, FeTextField } from "../../../FormElements";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import { updateObjectValue } from "../../../../utils/helper";
const TNMStagingClinical = ({ editedObj, onStateChange, updatedObj }) => {
  const [obj, setObj] = useState({
    t: null,
    n: null,
    m: null,
    type: "Clinical",
  });
  const [formObj, setFormObj] = useState({
    staging_clinicals: [
      {
        t: null,
        n: null,
        m: null,
        date: null,
        result: null,
      },
    ],
  });

  const [t, setT] = useState(null);
  const [n, setN] = useState(null);
  const [m, setM] = useState(null);
  const [clinicalValue, setClinicalValue] = useState(null);
  const [target_lasion, settarget_lasion] = useState([]);
  const [non_target_lasion, setnon_target_lasion] = useState([]);
  const [new_lasion, setnew_lasion] = useState([]);

  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObjectValue(formObj, updatedObj);
    }
    if (editedObj) {
      e = updateObjectValue(formObj, editedObj);
    }

    if (u) {
      setTimeout(() => {
        setFormObj(u);
        setObj(updateObjectValue(obj, u.staging_clinicals[0]));
      }, 100);
      return;
    } else if (!u && e) {
      setTimeout(() => {
        setFormObj(e);
        setObj(updateObjectValue(obj, e.staging_clinicals[0]));
      }, 100);
    }
  }, [updatedObj, editedObj]);
  useEffect(() => {
    gettarget_lasion();
    getnon_target_lasion();
    getnew_lasionl();
    return () => {};
  }, []);
  const gettarget_lasion = async () => {
    const { data } = await adminAPi.get("admin/tnm-records/clinical/t");
    console.log("DDDDAAA", data);
    settarget_lasion(data.data);
  };
  const getnon_target_lasion = async () => {
    const { data } = await adminAPi.get("admin/tnm-records/clinical/n");
    console.log("DDDDAAA", data);
    setnon_target_lasion(data.data);
  };
  const getnew_lasionl = async () => {
    const { data } = await adminAPi.get("admin/tnm-records/clinical/m");
    console.log("new_lasion", data);
    setnew_lasion(data.data);
  };

  useEffect(() => {
    if (!isEmpty(formObj)) onStateChange(formObj);
  }, [formObj]);

  useEffect(() => {
    if (!isEmpty(obj.t) || !isEmpty(obj.n) || !isEmpty(obj.m)) {
      getApi(obj);
    }
  }, [obj]);
  const getApi = async (param) => {
    try {
      const { data } = await adminAPi.post("admin/tnm-calculations", param);
      setClinicalValue(data?.data?.result);
      if (!data) {
        setFormObj({
          staging_clinicals: [
            {
              ...formObj.staging_clinicals[0],
              result: null,
            },
          ],
        });
        return;
      }
      setFormObj({
        staging_clinicals: [
          {
            ...formObj.staging_clinicals[0],
            result: data?.data?.result,
          },
        ],
      });
    } catch (error) {
      setClinicalValue(null);
      setFormObj({
        staging_clinicals: [
          {
            ...formObj.staging_clinicals[0],
            result: null,
          },
        ],
      });
    }
  };

  const handleChangeInput = (name, value) => {
    setObj({
      ...obj,
      [name]: value,
    });
    setFormObj({
      staging_clinicals: [
        {
          ...formObj.staging_clinicals[0],
          [name]: value,
        },
      ],
    });
  };

  return (
    <>
      {formObj.staging_clinicals && (
        <>
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              <Grid item sm={12}>
                TNM Staging (Clinical)
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box mb={2}>
              <FeAutocomplete
                //  disabled={editedObj}
                value={formObj?.staging_clinicals[0]?.t}
                options={target_lasion.map((o) => o.data)}
                id="T"
                label="T"
                onChange={(e, v) => {
                  setT(v);
                  if (v) {
                    handleChangeInput("t", v);
                  } else {
                    handleChangeInput("t", v);
                  }
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box mb={2}>
              <FeAutocomplete
                //  disabled={editedObj}
                options={non_target_lasion.map((o) => o.data)}
                id="N"
                label="N"
                value={formObj?.staging_clinicals[0]?.n}
                onChange={(e, v) => {
                  setN(v);
                  if (v) {
                    handleChangeInput("n", v);
                  } else {
                    handleChangeInput("n", v);
                  }
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box mb={2}>
              <FeAutocomplete
                //  disabled={editedObj}
                options={new_lasion.map((o) => o.data)}
                id="M"
                label="M"
                value={formObj?.staging_clinicals[0]?.m}
                onChange={(e, v) => {
                  setM(v);
                  if (v) {
                    handleChangeInput("m", v);
                  } else {
                    handleChangeInput("m", v);
                  }
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box mb={2}>
              <FeDesktopDatePicker
                label="Date"
                inputFormat="DD/MM/YYYY"
                value={formObj?.staging_clinicals[0]?.date}
                onChange={(val) => {
                  if (val)
                    setFormObj({
                      staging_clinicals: [
                        {
                          ...formObj.staging_clinicals[0],
                          date: dayjs(val).format("YYYY-MM-DD"),
                        },
                      ],
                    });
                  else {
                    setFormObj({
                      staging_clinicals: [
                        {
                          ...formObj.staging_clinicals[0],
                          date: null,
                        },
                      ],
                    });
                  }
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box mb={2}>
              <Grid item sm={6}>
                <FeTextField
                  label="Staging-Clinical"
                  key={formObj?.staging_clinicals[0]?.result}
                  value={formObj?.staging_clinicals[0]?.result}
                />
              </Grid>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default TNMStagingClinical;
