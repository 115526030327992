import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Box,
  Button,
  Divider,
  FormLabel,
  Grid,
  Radio,
  TableHead,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { FeAutocomplete, FeTextField } from "../../FormElements";

import doctorAPI from "../../../utils/doctorAPI";
import { debounce, isEmpty, merge } from "lodash";

import { useLocation, useNavigate, useParams } from "react-router-dom";

const initialObj = {
  patient_id: "",
  doctor_id: "",
  center_id: "",
};

const AppForm = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [obj, setObj] = useState(initialObj);

  const [docList, setDocList] = useState([]);
  const [docValue, setDocValue] = useState({});
  const [docParams, setDocParams] = useState({
    per_page: 15,
    page: 1,
  });
  const [centerList, setCenterList] = useState([]);
  const [centerValue, setCenterValue] = useState({});
  const [centerParams, setCenterParams] = useState({
    per_page: 15,
    page: 1,
  });
  const [patientList, setPatientList] = useState([]);
  const [patientValue, setPatientValue] = useState({});
  const [patientParams, setPatientParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getDoc();
    return () => {};
  }, [docParams]);

  const getDoc = async () => {
    const { data } = await doctorAPI.get("doctor/doctors", docParams);
    setDocList(data.data.data);
  };

  useEffect(() => {
    getCenter();
    return () => {};
  }, [centerParams]);

  const getCenter = async () => {
    const { data } = await doctorAPI.get("doctor/centers", centerParams);
    setCenterList(data.data.data);
  };
  useEffect(() => {
    getPatientList();
    return () => {};
  }, [patientParams]);

  const getPatientList = async () => {
    const { data } = await doctorAPI.get("doctor/patients", patientParams);
    setPatientList(data.data.data);
  };

  const handleOnInputCapturePatient = debounce((event) => {
    setPatientParams({ ...patientParams, mobile: event });
  }, 500);
  const handleOnInputCaptureCenter = debounce((event) => {
    setCenterParams({ ...centerParams, name: event });
  }, 500);

  const handleOnInputCaptureDoctor = debounce((event) => {
    setDocParams({ ...docParams, name: event });
  }, 500);
  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };
  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);

  async function getDetails(id) {
    const { data } = await doctorAPI.get(`doctor/appointments/${id}`);
    setObj(data.data);
    setObj({ ...data.data, ...data.data.patient });

    setPatientValue(data.data.patient);

    setDocValue(data.data.doctor);
    setCenterValue(data.data.center);
  }

  const handleSubmit = async (e, formValue) => {
    e.preventDefault();
    try {
      if (id) {
        const { data } = await doctorAPI.put(
          `doctor/appointments/${id}`,
          formValue
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      } else {
        const { data } = await doctorAPI.post("doctor/appointments", formValue);
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <Box component="form" position="relative">
      <Box width="100%">
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeAutocomplete
                  freeSolo
                  value={docValue}
                  options={docList}
                  onChange={(e, v) => {
                    setDocValue(v);
                    handleChangeInput("doctor_id", v.id);
                  }}
                  onInputChange={(e, v, r) => {
                    if (r === "input") {
                      handleOnInputCaptureDoctor(v);
                    } else if (r === "clear") {
                      setObj({ ...obj, doctor_id: null });
                      handleOnInputCaptureDoctor(v);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option.name || "";
                  }}
                  label="Consultant/ Primary Physician"
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeAutocomplete
                  freeSolo
                  value={centerValue}
                  options={centerList}
                  onChange={(e, v) => {
                    setCenterValue(v);
                    handleChangeInput("center_id", v.id);
                  }}
                  onInputChange={(e, v, r) => {
                    if (r === "input") {
                      handleOnInputCaptureCenter(v);
                    } else if (r === "clear") {
                      setObj({ ...obj, doctor_id: null });
                      handleOnInputCaptureCenter(v);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option.name || "";
                  }}
                  label="Center Name"
                />
              </Box>
            </Grid>

            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeAutocomplete
                  freeSolo
                  value={patientValue}
                  options={patientList}
                  onChange={(e, v) => {
                    setPatientValue(v);
                    setObj({ ...obj, patient_id: v.id });
                  }}
                  onInputChange={(e, v, r) => {
                    if (r === "input") {
                      handleOnInputCapturePatient(v);
                    } else if (r === "clear") {
                      handleOnInputCapturePatient(v);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option.phone || "";
                  }}
                  //   id="ConsultantPrimaryPhisician"
                  label="Patient Contact / Mobile No"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box width="100%" pr={2} pt={2}>
        <Box textAlign="right" maxWidth={760} mx="auto">
          <Button
            onClick={(e) => {
              let objtoPost = merge(obj);

              handleSubmit(e, objtoPost);
            }}
            variant="contained"
            size="large"
            fullWidth
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AppForm;
