import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useRef, useState } from "react";
import { FeAutocomplete, FeTextField } from "../../../FormElements";
import { withStyles, makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import { debounce, isEmpty, merge, omit } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import doctorAPI from "../../../../utils/doctorAPI";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import ChemoModDetails from "./ChemoModDetails";
import ChemoProtoDateCycle from "./ChemoProtoDateCycle";
import ChemotherapyProtocolForm from "./ChemotherapyProtocolForm";
import ClockStagingPathoForm from "./ClockStagingPathoForm";
import DiagnosisForm from "./DiagnosisForm";
import GradeForm from "./GradeForm";
import HispathoStagingClinicalForm from "./HispathoStagingClinicalForm";
import IHCForm from "./IHCForm";
import RadioTherapyForm from "./RadioTherapyForm";
import StagingPathologicalDetailsForm from "./StagingPathologicalDetailsForm";
import SurgeryChemoDetails from "./SurgeryChemoDetails";
import PatientDemography from "./PatientDemography";
import StatForm from "./StatForm";
import DiagGroupCalc from "./DiagGroupCalc";
import TNMStagingClinical from "./TNMStagingClinical";
import TNMStagingPathological from "./TNMStagingPathological";
import DiagnosisDiseases from "./DiagnosisDiseases";
import ClinicalResponseRate from "./ClinicalResponseRate";
import RadiologicalResponseRate from "./RadiologicalResponseRate";
import PathologicalResponseRate from "./PathologicalResponseRate";
import CancerMarker from "./CancerMarker";
import LineofTreatment from "./LineofTreatment";
import ChemoDetails from "./ChemDetails";
import MolecularForm from "./MolecularForm";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PreviewPage from "../../../PreviewPage";
import {
  checkEmptyDetailsFromArray,
  hasNonNullValue,
} from "../../../../utils/helper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const patientTypeOptions = ["Primary", "Foreign"];
const sexOptions = ["Male", "Female", "Others"];

const lateralityOptions = ["Right", "Left", "B/Lateral"];

const initialObj = {
  new_patient: true,
  cancer_type: "Lung",
  date: new Date(),
  name: "",
  phone: "",
  gender: "",
  date_of_birth: null,
  first_diagnosis_date: null,

  doctor_id: null,

  laterality: "",
  registration_no: "",
};
function getSteps() {
  return ["Patient Demography", "Diagnosis", "Treatment"];
}
const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "380px",
    backgroundColor: "var(--light-background)",
    color: "var(--dark-text)",
  },
  root: {
    width: "100%",
  },
  backButton: {
    // marginRight: theme.spacing(1),
    borderRadius: "0",
  },
  stepper: {
    // marginRight: theme.spacing(1),
    paddingBottom: "3rem",
  },
  nextButton: {
    borderRadius: "0",
    color: "white",
    background: "#0f2731",
    "&:hover": {
      backgroundImage: "linear-gradient(#8d0126, #230615);",
    },
  },
  icon: {
    fill: "#0f2731",
  },
  text: {
    fill: "white",
  },
  completed: {
    fill: "green",
  },
  instructions: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
}));
const PoFormLung = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(dayjs("2014-08-18T21:11:54"));
  const steps = getSteps();
  const [obj, setObj] = useState(initialObj);
  const [editedObj, setEditedObj] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [previewObj, setPreviewObj] = useState({});

  const [docList, setDocList] = useState([]);
  const [docValue, setDocValue] = useState({});
  const [docParams, setDocParams] = useState({
    per_page: 15,
    page: 1,
  });
  const [centerList, setCenterList] = useState([]);
  const [centerValue, setCenterValue] = useState({});
  const [centerParams, setCenterParams] = useState({
    per_page: 15,
    page: 1,
  });
  const [patientList, setPatientList] = useState([]);

  const [patientValue, setPatientValue] = useState({});
  const [patientParams, setPatientParams] = useState({
    per_page: 15,
    page: 1,
  });

  const [comoList, setComoList] = useState([]);
  const [comValue, setComValue] = useState([]);
  const [diagGroupList, setDiagGroupList] = useState([]);
  const [diagGroupValue, setDiagGroupValue] = useState([]);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleStep = (step) => {
    setActiveStep(step);
  };
  useEffect(() => {
    getComodities();
    getDiagGroups();
    return () => {};
  }, []);

  const getComodities = async () => {
    const { data } = await doctorAPI.get("doctor/comorbidity-records-all");
    setComoList(data.data);
  };

  const getDiagGroups = async () => {
    const { data } = await doctorAPI.get("doctor/diagnosis-group-records", {
      per_page: 50,
      page: 1,
    });
    setDiagGroupList(data.data.data);
  };
  // useEffect(() => {
  //   getDoc();
  //   return () => {};
  // }, [docParams]);

  // const getDoc = async () => {
  //   const { data } = await doctorAPI.get("doctor/doctors", docParams);
  //   setDocList(data.data.data);
  // };

  // useEffect(() => {
  //   getCenter();
  //   return () => {};
  // }, [centerParams]);

  const getCenter = async () => {
    const { data } = await doctorAPI.get("doctor/centers", centerParams);
    setCenterList(data.data.data);
  };
  useEffect(() => {
    getPatientList();
    return () => {};
  }, [patientParams]);

  const getPatientList = async () => {
    const { data } = await doctorAPI.get("doctor/patients", patientParams);
    setPatientList(data.data.data);
  };

  const handleOnInputCapturePatient = debounce((event) => {
    setPatientParams({ ...patientParams, phone: event });
  }, 500);
  const handleOnInputCaptureCenter = debounce((event) => {
    setCenterParams({ ...centerParams, name: event });
  }, 500);
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleOnInputCaptureDoctor = debounce((event) => {
    setDocParams({ ...docParams, name: event });
  }, 500);
  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };
  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);

  async function getDetails(id) {
    const { data } = await doctorAPI.get(`doctor/patient-observations/${id}`);
    setObj(data.data);
    setObj({ ...data.data, ...data.data.patient });

    setPatientValue(data.data.patient);
    setPatientList([data.data.patient]);
    setEditedObj(data.data);
    setComValue(data.data.comorbidities);

    setDocValue(data.data.doctor);
  }

  const DiagnosisFormRef = useRef(null);
  const ChemoModDetailsRef = useRef(null);
  const ChemotherapyProtocolRef = useRef(null);
  const ChemoDetailsRef = useRef(null);
  const ChemoProtoDateCycleRef = useRef(null);
  const ClockStagingPathoFormRef = useRef(null);
  const GradeFormRef = useRef(null);
  const HispathoStagingClinicalFormRef = useRef(null);
  const IHCFormRef = useRef(null);
  const RadioTherapyFormRef = useRef(null);
  const StagingPathologicalDetailsFormRef = useRef(null);
  const SurgeryChemoDetailsFormRef = useRef(null);
  const DemographyFormRef = useRef(null);
  const StatFormRef = useRef(null);
  const TNMStagingPathologicalRef = useRef(null);
  const TNMStagingClinicalRef = useRef(null);
  const ClinicalResRateRef = useRef(null);
  const RadioResRateRef = useRef(null);
  const PathoResRateRef = useRef(null);
  const DiagDiseaseRef = useRef(null);
  const CancerMarkerRef = useRef(null);
  const MoleCularRef = useRef(null);
  const DiagGroupRef = useRef(null);

  const handleSubmit = async (e, formValue) => {
    e.preventDefault();
    try {
      if (id) {
        // const { data } = await doctorAPI.put(
        //   `doctor/patient-observations/${id}`,
        //   formValue
        // );
        // navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      } else {
        try {
          const { data } = await doctorAPI.post(
            "doctor/patient-observations",
            formValue
          );

          setObj(initialObj);
          navigate("/doctor/patient-observations");
        } catch (error) {
          const inputString = obj.registration_no;
          let objj = inputString.replace(`${docValue?.center?.name} - `, "");
          setObj({
            ...obj,
            registration_no: objj,
          });
        }
      }
    } catch (error) {
      console.log(error.response);
      if (error.response && error.response.data) {
        alert(error.response.data.message);
      }
    }
  };
  console.log("OBBBB", obj);
  return (
    <Card sx={{ height: "100%!important" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CardContent>
          <Box component="form">
            <Box width="100%">
              <Stepper
                activeStep={activeStep}
                nonLinear
                className={classes.stepper}
              >
                {steps.map((label, i) => (
                  <Step key={label}>
                    <StepButton color="inherit" onClick={() => handleStep(i)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <Box>
                <Grid container spacing={2}>
                  {activeStep === 0 && (
                    <>
                      <Grid item xs={12} md={12}>
                        <Grid item xs={6} md={6}>
                          <Box mb={2}>
                            <FeDesktopDatePicker
                              //  disabled={editedObj}
                              label="Date"
                              inputFormat="DD/MM/YYYY"
                              value={obj.date}
                              onChange={(val) => {
                                handleChangeInput(
                                  "date",
                                  dayjs(val).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      {/* <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeAutocomplete
                            freeSolo
                            //  disabled={editedObj}
                            value={docValue}
                            options={docList}
                            onChange={(e, v) => {
                              setDocValue(v);
                              handleChangeInput("doctor_id", v.id);
                            }}
                            onInputChange={(e, v, r) => {
                              if (r === "input") {
                                handleOnInputCaptureDoctor(v);
                              } else if (r === "clear") {
                                setObj({ ...obj, doctor_id: null });
                                handleOnInputCaptureDoctor(v);
                              }
                            }}
                            getOptionLabel={(option) => {
                              return option.name || "";
                            }}
                            label="Consultant/ Primary Physician"
                          />
                        </Box>
                      </Grid> */}
                      {/* <Grid item xs={6} md={4}>
                  <Box mb={2}>
                    <FeAutocomplete
                      freeSolo
                      value={centerValue}
                    //  disabled={editedObj}
                      options={centerList}
                      onChange={(e, v) => {
                        setCenterValue(v);
                        handleChangeInput("center_id", v.id);
                      }}
                      onInputChange={(e, v, r) => {
                        if (r === "input") {
                          handleOnInputCaptureCenter(v);
                        } else if (r === "clear") {
                          setObj({ ...obj, doctor_id: null });
                          handleOnInputCaptureCenter(v);
                        }
                      }}
                      getOptionLabel={(option) => {
                        return option.name || "";
                      }}
                      label="Center Name"
                    />
                  </Box>
                </Grid> */}
                      <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeTextField
                            onBlur={(e) =>
                              handleChangeInput(
                                "registration_no",
                                e.target.value
                              )
                            }
                            //  disabled={editedObj}
                            defaultValue={obj.registration_no}
                            id="RegistrationNo"
                            label="Registration No"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {docValue?.center?.name} -
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeAutocomplete
                            freeSolo
                            //  disabled={editedObj}
                            value={patientValue}
                            options={patientList}
                            onChange={async (e, v) => {
                              setPatientValue(v);
                              const { data } = await doctorAPI.get(
                                `doctor/patients/${v.id}`
                              );

                              if (
                                data?.data?.patient_observations?.length > 0
                              ) {
                                let history = null;
                                let lastObj =
                                  data.data.patient_observations[
                                    data.data.patient_observations.length - 1
                                  ];
                                let lastobjWithoutHistory = null;

                                if (lastObj?.patient_history) {
                                  history = lastObj?.patient_history;
                                  delete lastObj.patient_history;
                                }
                                setEditedObj(lastObj);
                                console.log("DLDJKD", lastObj);
                                if (lastobjWithoutHistory) {
                                }
                                if (history) {
                                  let merged = merge(obj, v, history);

                                  setObj(merged);
                                } else {
                                  setObj({ ...obj, ...v });
                                }
                              } else {
                                setObj({ ...obj, ...v });
                              }
                            }}
                            onBlur={(e) => {
                              handleChangeInput("phone", e.target.value);
                              console.log("false", e.target.value);
                            }}
                            onInputChange={(e, v, r) => {
                              if (r === "input") {
                                handleOnInputCapturePatient(v);
                              } else if (r === "clear") {
                                setObj(initialObj);

                                handleOnInputCapturePatient(v);
                              }
                            }}
                            getOptionLabel={(option) => {
                              return option.phone || "";
                            }}
                            //   id="ConsultantPrimaryPhisician"
                            label="Patient Contact / Mobile No"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeTextField
                            //  disabled={editedObj}
                            onBlur={(e) =>
                              handleChangeInput("name", e.target.value)
                            }
                            defaultValue={obj.name}
                            id="PatientName"
                            label="Patient Name"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box
                          sx={{
                            "& .MuiFormControl-root": {
                              width: "100%",
                            },
                          }}
                        >
                          <FeDesktopDatePicker
                            //  disabled={editedObj}
                            label="Date of Birth"
                            inputFormat="DD/MM/YYYY"
                            value={obj.date_of_birth}
                            onChange={(v) => {
                              const name = "date_of_birth";
                              const value = dayjs(v).format("YYYY-MM-DD");
                              handleChangeInput(name, value);
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeAutocomplete
                            onChange={(e, v) => {
                              handleChangeInput("gender", v);
                            }}
                            //  disabled={editedObj}
                            value={obj.gender}
                            options={sexOptions}
                            id="Sex"
                            label="Sex"
                          />
                        </Box>
                      </Grid>

                      <PatientDemography
                        pObj={obj}
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          DemographyFormRef.current = state;
                          // setUpdatedObj(state);
                        }}
                        updatedObj={DemographyFormRef.current}
                      />

                      <Grid item xs={6} md={4}>
                        <Box
                          sx={{
                            "& .MuiFormControl-root": {
                              width: "100%",
                            },
                          }}
                        >
                          <FeDesktopDatePicker
                            //  disabled={editedObj}
                            label="Date of 1st Diagnosis"
                            inputFormat="DD/MM/YYYY"
                            value={obj.first_diagnosis_date}
                            onChange={(v) => {
                              const name = "first_diagnosis_date";
                              const value = dayjs(v).format("YYYY-MM-DD");
                              handleChangeInput(name, value);
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeAutocomplete
                            multiple
                            //  disabled={editedObj}
                            disableCloseOnSelect
                            getOptionLabel={(option) =>
                              editedObj ? option.detail : option.name
                            }
                            value={comValue}
                            onChange={(e, v) => {
                              handleChangeInput(
                                "comorbidities",
                                v.map((o) => {
                                  return { detail: o.name };
                                })
                              );

                              setComValue(v);
                            }}
                            renderOption={(props, option, { selected }) => {
                              return (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.name}
                                </li>
                              );
                            }}
                            options={comoList}
                            id="Comorbidities"
                            label="Comorbidities"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.nextButton}
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Grid>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <DiagnosisDiseases
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          DiagDiseaseRef.current = state;
                        }}
                        updatedObj={DiagDiseaseRef.current}
                      />
                      <DiagnosisForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          DiagnosisFormRef.current = state;
                        }}
                        updatedObj={DiagnosisFormRef.current}
                      />
                      <HispathoStagingClinicalForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          HispathoStagingClinicalFormRef.current = state;
                        }}
                        updatedObj={HispathoStagingClinicalFormRef.current}
                      />
                      <GradeForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          GradeFormRef.current = state;
                        }}
                        updatedObj={GradeFormRef.current}
                      />
                      <ClockStagingPathoForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          ClockStagingPathoFormRef.current = state;
                        }}
                        updatedObj={ClockStagingPathoFormRef.current}
                      />
                      <DiagGroupCalc
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          DiagGroupRef.current = state;
                        }}
                        updatedObj={DiagGroupRef.current}
                      />
                      <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeAutocomplete
                            //  disabled={editedObj}
                            options={lateralityOptions}
                            value={obj.laterality}
                            id="Laterality"
                            label="Laterality"
                            onChange={(e, v) => {
                              handleChangeInput("laterality", v);
                            }}
                          />
                        </Box>
                      </Grid>
                      <IHCForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          IHCFormRef.current = state;
                        }}
                        updatedObj={IHCFormRef.current}
                      />
                      <TNMStagingClinical
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          TNMStagingClinicalRef.current = state;
                        }}
                        updatedObj={TNMStagingClinicalRef.current}
                      />
                      <TNMStagingPathological
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          TNMStagingPathologicalRef.current = state;
                        }}
                        updatedObj={TNMStagingPathologicalRef.current}
                      />
                      <StagingPathologicalDetailsForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          StagingPathologicalDetailsFormRef.current = state;
                        }}
                        updatedObj={StagingPathologicalDetailsFormRef.current}
                      />
                      <MolecularForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          MoleCularRef.current = state;
                        }}
                        updatedObj={MoleCularRef.current}
                      />
                      <CancerMarker
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          CancerMarkerRef.current = state;
                        }}
                        updatedObj={CancerMarkerRef.current}
                      />
                      <Grid item xs={6} md={6}></Grid>
                      <Grid item xs={12} md={12}>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.nextButton}
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Grid>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <ChemoModDetails
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          ChemoModDetailsRef.current = state;
                        }}
                        updatedObj={ChemoModDetailsRef.current}
                      />
                      <ChemoProtoDateCycle
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          ChemoProtoDateCycleRef.current = state;
                        }}
                        updatedObj={ChemoProtoDateCycleRef.current}
                      />
                      <ChemotherapyProtocolForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          ChemotherapyProtocolRef.current = state;
                        }}
                        updatedObj={ChemotherapyProtocolRef.current}
                      />
                      <ChemoDetails
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          ChemoDetailsRef.current = state;
                        }}
                        updatedObj={ChemoDetailsRef.current}
                      />

                      <SurgeryChemoDetails
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          SurgeryChemoDetailsFormRef.current = state;
                        }}
                        updatedObj={SurgeryChemoDetailsFormRef.current}
                      />
                      <RadioTherapyForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          RadioTherapyFormRef.current = state;
                        }}
                        updatedObj={RadioTherapyFormRef.current}
                      />

                      <Grid item xs={6} md={4}></Grid>

                      <ClinicalResponseRate
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          ClinicalResRateRef.current = state;
                        }}
                        updatedObj={ClinicalResRateRef.current}
                      />
                      <RadiologicalResponseRate
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          RadioResRateRef.current = state;
                        }}
                        updatedObj={RadioResRateRef.current}
                      />
                      <PathologicalResponseRate
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          PathoResRateRef.current = state;
                        }}
                        updatedObj={PathoResRateRef.current}
                      />
                      <StatForm
                        diagDate={obj.first_diagnosis_date}
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          StatFormRef.current = state;
                        }}
                        updatedObj={StatFormRef.current}
                      />
                      <Grid item xs={12} md={12}>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          Back
                        </Button>
                        <Button
                          disabled={activeStep === 0}
                          onClick={() => {
                            setShowPreview(true);
                            let objtoPost = merge(
                              obj,
                              ChemoModDetailsRef.current,
                              ChemoProtoDateCycleRef.current,
                              ChemotherapyProtocolRef.current,
                              ChemoDetailsRef.current,
                              ClockStagingPathoFormRef.current,
                              DiagGroupRef.current,
                              DiagnosisFormRef.current,
                              GradeFormRef.current,
                              HispathoStagingClinicalFormRef.current,
                              IHCFormRef.current,
                              RadioTherapyFormRef.current,
                              DiagDiseaseRef.current,
                              CancerMarkerRef.current,
                              StagingPathologicalDetailsFormRef.current,
                              SurgeryChemoDetailsFormRef.current,
                              DemographyFormRef.current,
                              StatFormRef.current,
                              TNMStagingPathologicalRef.current,
                              TNMStagingClinicalRef.current,
                              ClinicalResRateRef.current,
                              RadioResRateRef.current,
                              PathoResRateRef.current,
                              MoleCularRef.current
                            );

                            if (!isEmpty(docValue)) {
                              objtoPost.registration_no = `${docValue?.center?.name} - ${objtoPost.registration_no}`;
                            }
                            if (!isEmpty(patientValue)) {
                              objtoPost.new_patient = false;
                            } else {
                              objtoPost.new_patient = true;
                            }

                            setPreviewObj(objtoPost);
                          }}
                          variant="success"
                        >
                          Preview
                        </Button>
                        <Button
                          //  disabled={editedObj}
                          onClick={(e) => {
                            let objtoPost = merge(
                              obj,
                              ChemoModDetailsRef.current,
                              ChemoProtoDateCycleRef.current,
                              ChemotherapyProtocolRef.current,
                              ChemoDetailsRef.current,
                              ClockStagingPathoFormRef.current,
                              DiagGroupRef.current,
                              DiagnosisFormRef.current,
                              GradeFormRef.current,
                              HispathoStagingClinicalFormRef.current,
                              IHCFormRef.current,
                              RadioTherapyFormRef.current,
                              DiagDiseaseRef.current,
                              CancerMarkerRef.current,
                              StagingPathologicalDetailsFormRef.current,
                              SurgeryChemoDetailsFormRef.current,
                              DemographyFormRef.current,
                              StatFormRef.current,
                              TNMStagingPathologicalRef.current,
                              TNMStagingClinicalRef.current,
                              ClinicalResRateRef.current,
                              RadioResRateRef.current,
                              PathoResRateRef.current,
                              MoleCularRef.current
                            );
                            if (
                              checkEmptyDetailsFromArray(
                                objtoPost.chemotherapy_modalities
                              )
                            ) {
                              delete objtoPost.chemotherapy_modalities;
                            }
                            if (
                              checkEmptyDetailsFromArray(
                                objtoPost.cancer_markers,
                                "name"
                              )
                            ) {
                              delete objtoPost.cancer_markers;
                            }
                            if (
                              checkEmptyDetailsFromArray(objtoPost.diagnoses)
                            ) {
                              delete objtoPost.diagnoses;
                            }
                            if (
                              checkEmptyDetailsFromArray(
                                objtoPost.histopathologies
                              )
                            ) {
                              delete objtoPost.histopathologies;
                            }
                            if (
                              checkEmptyDetailsFromArray(
                                objtoPost.molecular_pathologies
                              )
                            ) {
                              delete objtoPost.molecular_pathologies;
                            }
                            if (
                              checkEmptyDetailsFromArray(
                                objtoPost.past_treatment_histories
                              )
                            ) {
                              delete objtoPost.past_treatment_histories;
                            }
                            if (
                              checkEmptyDetailsFromArray(
                                objtoPost.radiotherapy_schedules
                              )
                            ) {
                              delete objtoPost.radiotherapy_schedules;
                            }
                            if (
                              checkEmptyDetailsFromArray(
                                objtoPost.surgeries,
                                "modality"
                              )
                            ) {
                              delete objtoPost.surgeries;
                            }
                            if (!hasNonNullValue(objtoPost.diagnosis_groups)) {
                              delete objtoPost.diagnosis_groups;
                            }
                            if (!hasNonNullValue(objtoPost.ihcs)) {
                              delete objtoPost.ihcs;
                            }
                            if (!hasNonNullValue(objtoPost.staging_clinicals)) {
                              delete objtoPost.staging_clinicals;
                            }
                            if (
                              !hasNonNullValue(
                                objtoPost.staging_pathological_details
                              )
                            ) {
                              delete objtoPost.staging_pathological_details;
                            }
                            if (
                              !hasNonNullValue(objtoPost.staging_pathologicals)
                            ) {
                              delete objtoPost.staging_pathologicals;
                            }
                            if (!isEmpty(docValue)) {
                              obj.registration_no = `${docValue?.center?.name} - ${obj.registration_no}`;
                            }

                            if (!isEmpty(patientValue)) {
                              objtoPost.new_patient = false;
                            } else {
                              objtoPost.new_patient = true;
                            }

                            // if (objtoPost.doctor_id === null) {
                            //   alert("Please select a doctor");
                            //   return;
                            // }
                            // if (objtoPost.phone === "") {
                            //   alert("Please select a patient");
                            //   return;
                            // }
                            handleSubmit(e, objtoPost);
                          }}
                          className={classes.nextButton}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Box>
            <Box width="100%" pr={2} pt={2}>
              <Box textAlign="right" maxWidth={760} mx="auto"></Box>
            </Box>
          </Box>
        </CardContent>
      </LocalizationProvider>{" "}
      <Dialog
        fullWidth
        open={showPreview}
        onClose={() => setShowPreview(false)}
        maxWidth={"xl"}
      >
        <DialogTitle>Preview</DialogTitle>
        <DialogContent>
          <PreviewPage obj={previewObj} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPreview(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default PoFormLung;
