import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, Button, FormLabel, Grid, Radio, TableHead } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useEffect, useState } from "react";
import { FeAutocomplete, FeTextField } from "../FormElements";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FeDesktopDatePicker from "../FormElements/FeDesktopDatePicker";

import dayjs from "dayjs";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const consultantPrimaryPhisicianOptions = [
  "Dr Md Salim Reza",
  "Dr Arunangshu Das",
  "Dr Shafatujjahan",
  "Dr Ferdous Ara Begum",
  "Dr Md Arifur Rahman",
  "Prof Dr Salma Sultana",
];
const centerNameOptions = ["Dhaka", "Chittagong", "Sylhet"];
const patientTypeOptions = ["Primary", "Foreign"];
const sexOptions = ["Male", "Female"];
const comorbiditiesOptions = [
  "None",
  "T2DM",
  "HTN",
  "Bronchial Asthma",
  "Hypo/Hyper thoiroidism",
  "IHD",
  "OA",
  "CVA",
  "Others",
];
const diagnosisGroupOptions = [
  "Luminal A (HR +ve, Her2 -ve, Ki67-ve(<14%)",
  "Luminal B (Her2 Negative)/ HR +ve, Her2 -ve, Ki 67 high",
  "Luminal B (Her2 Positive)/ HR +ve, Her2 +ve, Any Ki 67",
  "Her2 Positive (HR -ve)",
  "Basal Like/ TNBC",
  "Not Applicable/ Not Done",
];
const lateralityOptions = ["Right", "Left", "B/Lateral"];
// const histopathologyOptions = [
//   "Adenocarcinoma",
//   "Anaplastic Carcinoma",
//   "Metaplastic Carcinoma",
//   "Duct Cell Carcinoma",
//   "Lobular Carcinom",
//   "Leiomyosarcoma",
//   "Lymphoma- NHL",
//   "Neuroendocrine Carcinoma",
//   "Papillary Carcinoma",
//   "Sarcoma- Soft Tissue",
//   "Squamous Cell",
//   "Small Cell",
// ];
const stagingClinicalOptions = [
  "T0/X",
  "T1 (<20mm)",
  "T2 (20mm to 50mm)",
  "T3 (More than 50mm)",
  "T4",
  "N0/X",
  "N1 (Ipsi L-I, II)",
  "N2 (Ipsi LI, II fixed OR IMLN)",
  "N3 (Ipsi L-III)",
  "M0/X",
  "M1 (Distant)",
];
const stagingPathologicalOptions = [
  "T0",
  "Tis",
  "T1 (<20mm)",
  "T2 (20mm to 50mm)",
  "T3 (More than 50mm)",
  "T4",
  "N0/X",
  "N1 (1-3)",
  "N2 (4-9)",
  "N3 (>10 LNs)",
  "M1 (Liver/ Lung/ Bone/ Brain)",
  "M0/x",
];
const surgeryModalityOptions = [
  "Lumpectomy with ALND",
  "Lumpectomy with SnLNBx",
  "Mastectomy with ALND",
  "Mastectomy with SnLNBx",
  "Unplanned Limpectomy",
];
const chemotherapyModalityOptions = [
  "Neoadjuvant ChT",
  "Adjuvant ChT",
  "Metastatic ChT",
  "BSC",
  "Others",
];

const PatientCollaborationForm = () => {
  const [value, setValue] = useState(dayjs("2014-08-18T21:11:54"));
  const [histopathologyOptions, setHistopathologyOptions] = useState([]);
  const [selectedHistopathology, setSelectedHispathology] = useState([]);

  useEffect(() => {
    getAPI();

    return () => {};
  }, []);

  const getAPI = async () => {
    // const { data } = await request.get(
    //   "admin/patient-observation-records?group=Histopathology"
    // );
    // setHistopathologyOptions(data.data.data);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <Box component="form" position="relative">
      <Box width="100%">
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeAutocomplete
                  options={consultantPrimaryPhisicianOptions}
                  id="ConsultantPrimaryPhisician"
                  label="Consultant/ Primary Physician"
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeAutocomplete
                  options={centerNameOptions}
                  id="CenterName"
                  label="Center Name"
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeTextField id="RegistrationNo" label="Registration No" />
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeTextField
                  id="PatientContact"
                  label="Patient Contact / Mobile No"
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeAutocomplete
                  options={patientTypeOptions}
                  id="PatientType"
                  label="Patient Type"
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeTextField id="PatientName" label="Patient Name" required />
              </Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <Box mb={2}>
                <FeTextField id="Age" label="Age" />
              </Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <Box mb={2}>
                <FeAutocomplete options={sexOptions} id="Sex" label="Sex" />
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeTextField
                  id="Diagnosisindetails"
                  label="Diagnosis in details"
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeTextField
                  id="Past Treatment History"
                  label="Past Treatment History"
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeAutocomplete
                  multiple
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  options={comorbiditiesOptions}
                  id="Comorbidities"
                  label="Comorbidities"
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeAutocomplete
                  multiple
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  options={diagnosisGroupOptions}
                  id="DiagnosisGroup"
                  label="Diagnosis Group"
                />
              </Box>
            </Grid>

            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeAutocomplete
                  options={lateralityOptions}
                  id="Laterality"
                  label="Laterality"
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box mb={2}>
                <FeAutocomplete
                  multiple
                  disableCloseOnSelect
                  value={selectedHistopathology}
                  getOptionLabel={(option) => option.value}
                  onChange={(event, newValue) => {
                    console.log("JHHH", newValue);
                    setSelectedHispathology(newValue);
                  }}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.value}
                      </li>
                    );
                  }}
                  options={histopathologyOptions}
                  id="Histopathology"
                  label="Histopathology"
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box mb={2}>
                <FeAutocomplete
                  multiple
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  options={stagingClinicalOptions}
                  id="StagingClinical"
                  label="Staging- Clinical"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box mb={2}>
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <Box py={1.5} pl={2}>
                    <FormLabel>Clock Position (USG/Mammogram based)</FormLabel>
                  </Box>
                  <Table
                    size="small"
                    sx={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.23)",
                      "& tr": {
                        "&:last-child": {
                          "& td, & th": {
                            borderBottom: 0,
                          },
                        },
                      },
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">1</TableCell>
                        <TableCell align="center">2</TableCell>
                        <TableCell align="center">3</TableCell>
                        <TableCell align="center">4</TableCell>
                        <TableCell align="center">5</TableCell>
                        <TableCell align="center">6</TableCell>
                        <TableCell align="center">7</TableCell>
                        <TableCell align="center">8</TableCell>
                        <TableCell align="center">9</TableCell>
                        <TableCell align="center">10</TableCell>
                        <TableCell align="center">11</TableCell>
                        <TableCell align="center">12</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Left
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Right
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box mb={2}>
                <FeAutocomplete
                  multiple
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  options={stagingPathologicalOptions}
                  id="StagingPathological"
                  label="Staging- Pathological"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box mb={2}>
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <Box py={1.5} pl={2}>
                    <FormLabel>Staging- Pathological details</FormLabel>
                  </Box>
                  <Table
                    size="small"
                    sx={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.23)",
                      "& tr": {
                        "&:last-child": {
                          "& td, & th": {
                            borderBottom: 0,
                          },
                        },
                      },
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Positive</TableCell>
                        <TableCell align="center">Negative</TableCell>
                        <TableCell align="center">Not Done</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          LVSI
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__LVSI" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__LVSI" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__LVSI" />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          PNI
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__PNI" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__PNI" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__PNI" />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Margin
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Margin" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Margin" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Margin" />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Ki 67
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__ki67" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__ki67" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__ki67" />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box mb={2}>
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <Box py={1.5} pl={2}>
                    <FormLabel>Grade</FormLabel>
                  </Box>
                  <Table
                    size="small"
                    sx={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.23)",
                      "& tr": {
                        "&:last-child": {
                          "& td, & th": {
                            borderBottom: 0,
                          },
                        },
                      },
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1"
                            labelPlacement="top"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="2"
                            labelPlacement="top"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="3"
                            labelPlacement="top"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="4"
                            labelPlacement="top"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box mb={2}>
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    maxHeight: 360,
                  }}
                >
                  <Box py={1.5} pl={2}>
                    <FormLabel>IHC</FormLabel>
                  </Box>
                  <Table
                    size="small"
                    stickyHeader
                    sx={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.23)",
                      "& tr": {
                        "&:last-child": {
                          "& td": {
                            borderBottom: 0,
                          },
                        },
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Positive</TableCell>
                        <TableCell align="center">Negative</TableCell>
                        <TableCell align="center">Positive 3+</TableCell>
                        <TableCell align="center">Equvocal 2+</TableCell>
                        <TableCell align="center">Negative 1+</TableCell>
                        <TableCell align="center">Not Done</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          ER
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__ER" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__ER" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__ER" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__ER" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__ER" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__ER" />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          PR
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__PR" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__PR" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__PR" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__PR" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__PR" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__PR" />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          HER2
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__HER2" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__HER2" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__HER2" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__HER2" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__HER2" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__HER2" />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          FISH
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__FISH" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__FISH" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__FISH" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__FISH" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__FISH" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__FISH" />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Synaptophysin
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Synaptophysin" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Synaptophysin" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Synaptophysin" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Synaptophysin" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Synaptophysin" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Synaptophysin" />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Chromogranin
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Chromogranin" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Chromogranin" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Chromogranin" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Chromogranin" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Chromogranin" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Chromogranin" />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          GATA
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__GATA" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__GATA" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__GATA" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__GATA" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__GATA" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__GATA" />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Others
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Others" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Others" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Others" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Others" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Others" />
                        </TableCell>
                        <TableCell align="center">
                          <Radio name="radio__Others" />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box mb={2}>
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    maxHeight: 360,
                  }}
                >
                  <Box py={1.5} pl={2}>
                    <FormLabel>Treatment Protocol</FormLabel>
                  </Box>
                  <Table
                    size="small"
                    stickyHeader
                    sx={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.23)",
                      "& tr": {
                        "&:last-child": {
                          "& td": {
                            borderBottom: 0,
                          },
                        },
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Actinomycin</TableCell>
                        <TableCell align="center">Bleomycin</TableCell>
                        <TableCell align="center">Bevacizumab</TableCell>
                        <TableCell align="center">Cyclophosphamide</TableCell>
                        <TableCell align="center">Carboplatin</TableCell>
                        <TableCell align="center">Capecitabine</TableCell>
                        <TableCell align="center">Cetuximab</TableCell>
                        <TableCell align="center">Cisplatin</TableCell>
                        <TableCell align="center">Docetaxel</TableCell>
                        <TableCell align="center">Dacarbazine</TableCell>
                        <TableCell align="center">Doxorubicin</TableCell>
                        <TableCell align="center">Denosumab</TableCell>
                        <TableCell align="center">Epirubicin</TableCell>
                        <TableCell align="center">Etoposide</TableCell>
                        <TableCell align="center">5FU</TableCell>
                        <TableCell align="center">Gemcitabine</TableCell>
                        <TableCell align="center">Nab-Paclitaxel</TableCell>
                        <TableCell align="center">Paclitaxel</TableCell>
                        <TableCell align="center">Partuzumab</TableCell>
                        <TableCell align="center">Pemetraxed</TableCell>
                        <TableCell align="center">Rituximab</TableCell>
                        <TableCell align="center">Trastuzumab</TableCell>
                        <TableCell align="center">Vinblastin</TableCell>
                        <TableCell align="center">Vincristine</TableCell>
                        <TableCell align="center">Vinorelbine</TableCell>
                        <TableCell align="center">Eribulin</TableCell>
                        <TableCell align="center">
                          Liposomal Doxorubicin
                        </TableCell>
                        <TableCell align="center">Everolimus</TableCell>
                        <TableCell align="center">Zoledronic Acid</TableCell>
                        <TableCell align="center">Tamoxoifen</TableCell>
                        <TableCell align="center">Letrozole</TableCell>
                        <TableCell align="center">Anastrozole</TableCell>
                        <TableCell align="center">Exemestane</TableCell>
                        <TableCell align="center">Ixabepilone</TableCell>
                        <TableCell align="center">Megestrol Acetate</TableCell>
                        <TableCell align="center">Fulvestrant</TableCell>
                        <TableCell align="center">Abemaciclib</TableCell>
                        <TableCell align="center">Palbociclib</TableCell>
                        <TableCell align="center">Ribociclib</TableCell>
                        <TableCell align="center">Alpesilib</TableCell>
                        <TableCell align="center">Lapatinib</TableCell>
                        <TableCell align="center">Neratinib</TableCell>
                        <TableCell align="center">
                          Ado-Trastuzumab Emtansine (T-DM1)
                        </TableCell>
                        <TableCell align="center">
                          Fam-Trastuzumab Deruxtecan-nxki
                        </TableCell>
                        <TableCell align="center">
                          Olaparib (germline BRCA1/2 mutation)
                        </TableCell>
                        <TableCell align="center">
                          Entrectinib (NTRK gene fusion)
                        </TableCell>
                        <TableCell align="center">
                          Larotrectinib (NTRK gene fusion)
                        </TableCell>
                        <TableCell align="center">
                          Pembrolizumab (MSI-H/dMMR)
                        </TableCell>
                        <TableCell align="center">Goserelin</TableCell>
                        <TableCell align="center">Leuprolide</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Adjuvant Endocrine Therapy- Hormone Receptor-Positive
                          Disease (Premenopausal at diagnosis)
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Adjuvant Endocrine Therapy- Hormone Receptor-Positive
                          Disease (Postmenopausal at diagnosis)
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Preoperative/Adjuvant Treatment- HER2-negative Disease
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Preoperative/Adjuvant Treatment- HER2-positive Disease
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Systemic for Recurrent or Stage IV (M1) Disease-
                          HER2-Negative and Postmenopausal or Premenopausal
                          Receiving Ovarian Ablation or Suppression
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Systemic for Recurrent or Stage IV (M1) Disease-
                          HER2-Positive and Postmenopausal or Premenopausal
                          Receiving Ovarian Ablation or Suppression
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Treatment Regimens For Recurrent or Stage IV (M1)
                          Disease- HER2-Negative
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Treatment Regimens For Recurrent or Stage IV (M1)
                          Disease- HER2-Positive
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Additional Targeted Therapies and Associated Biomarker
                          Testing for Recurrent or Stage IV (M1) Disease1,a
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeTextField
                  id="CurrentChemoProtocol"
                  label="Current Treatment Protocol"
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box
                mb={2}
                sx={{
                  "& .MuiFormControl-root": {
                    width: "100%",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FeDesktopDatePicker
                    label="Date desktop"
                    inputFormat="DD/MM/YYYY"
                    value={value}
                    onChange={handleChange}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeAutocomplete
                  options={surgeryModalityOptions}
                  id="Surgery Modality"
                  label="Surgery Modality"
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={8}>
              <Box mb={2}>
                <FeAutocomplete
                  multiple
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  options={chemotherapyModalityOptions}
                  id="Treatment Modality"
                  label="Treatment Modality"
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box mb={2}>
                <FeTextField
                  id="ChemotherapyDetails(Chronology)"
                  label="Treatment Details (Chronology)"
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={12}>
              <Box mb={2}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Radiotherapy Schedule
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="radioRS__01"
                      control={<Radio />}
                      label="50Gy in 25#"
                    />
                    <FormControlLabel
                      value="radioRS__02"
                      control={<Radio />}
                      label="50Gy in 25# with Electron Boost"
                    />
                    <FormControlLabel
                      value="radioRS__03"
                      control={<Radio />}
                      label="40.05Gy in 15#"
                    />
                    <FormControlLabel
                      value="radioRS__04"
                      control={<Radio />}
                      label="40.05 in 15# with Electron Boost"
                    />
                    <FormControlLabel
                      value="radioRS__05"
                      control={<Radio />}
                      label="Others"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box width="100%" pr={2} pt={2}>
        <Box textAlign="right" maxWidth={760} mx="auto">
          <Button variant="contained" size="large" fullWidth>
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PatientCollaborationForm;
