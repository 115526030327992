import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  TableContainer,
  TableHead,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import Checkbox from "@mui/material/Checkbox";
import { FeTextField, FeAutocomplete } from "../../../FormElements";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";
import adminAPi from "../../../../utils/adminAPi";
import { isEmpty } from "lodash";
import { updateObjectValue } from "../../../../utils/helper";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const radioOptions = ["positive", "negative", "not_done"];
const StagingPathologicalDetailsForm = ({
  onStateChange,
  editedObj,
  updatedObj,
}) => {
  const [obj, setObj] = useState({
    staging_pathological_details: [
      {
        lvsi: null,
        psi: null,
        margin: null,
        ki67: null,
      },
    ],
  });
  useEffect(() => {
    if (editedObj) {
      if (!isEmpty(editedObj.staging_pathological_details))
        setObj(Object.assign({}, obj, editedObj));
    }
  }, [editedObj]);
  useEffect(() => {
    if (updatedObj) {
      const u = updateObjectValue(obj, updatedObj);
      setTimeout(() => {
        setObj(u);
      }, 100);
    }
  }, [updatedObj]);
  const handleChangeRadioBox = (e) => {
    const { name, value } = e.target;

    const list = [...obj.staging_pathological_details];
    list[0][name] = value;
    setObj({ staging_pathological_details: list });
  };

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);
  return (
    <>
      <Grid item xs={12} md={12}>
        <Box mb={2}>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.23)",
            }}
          >
            <Box py={1.5} pl={2}>
              <FormLabel>Staging- Pathological details</FormLabel>
            </Box>
            <Table
              size="small"
              sx={{
                borderTop: "1px solid rgba(0, 0, 0, 0.23)",
                "& tr": {
                  "&:last-child": {
                    "& td, & th": {
                      borderBottom: 0,
                    },
                  },
                },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell>Undo</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Positive</TableCell>
                  <TableCell align="center">Negative</TableCell>
                  <TableCell align="center">Not Done</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <IconButton
                      type="button"
                      onClick={() => {
                        const list = [...obj.staging_pathological_details];
                        list[0]["lvsi"] = null;
                        setObj({ staging_pathological_details: list });
                      }}
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <UndoIcon />
                    </IconButton>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    LVSI
                  </TableCell>
                  {radioOptions.map((item, i) => (
                    <TableCell key={i} align="center">
                      <Radio
                        //  disabled={editedObj}
                        checked={
                          obj.staging_pathological_details[0].lvsi === item
                        }
                        onChange={(e) => handleChangeRadioBox(e)}
                        value={item}
                        name="lvsi"
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <IconButton
                      type="button"
                      onClick={() => {
                        const list = [...obj.staging_pathological_details];
                        list[0]["pni"] = null;
                        setObj({ staging_pathological_details: list });
                      }}
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <UndoIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    PNI
                  </TableCell>
                  {radioOptions.map((item, i) => (
                    <TableCell key={i} align="center">
                      <Radio
                        checked={
                          obj.staging_pathological_details[0].pni === item
                        }
                        //  disabled={editedObj}
                        onChange={(e) => handleChangeRadioBox(e)}
                        value={item}
                        name="pni"
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <IconButton
                      type="button"
                      onClick={() => {
                        const list = [...obj.staging_pathological_details];
                        list[0]["margin"] = null;
                        setObj({ staging_pathological_details: list });
                      }}
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <UndoIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Margin
                  </TableCell>
                  {radioOptions.map((item, i) => (
                    <TableCell key={i} align="center">
                      <Radio
                        checked={
                          obj.staging_pathological_details[0].margin === item
                        }
                        //  disabled={editedObj}
                        onChange={(e) => handleChangeRadioBox(e)}
                        value={item}
                        name="margin"
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <IconButton
                      type="button"
                      onClick={() => {
                        const list = [...obj.staging_pathological_details];
                        list[0]["ki67"] = null;
                        setObj({ staging_pathological_details: list });
                      }}
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <UndoIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Ki 67
                  </TableCell>
                  {radioOptions.map((item, i) => (
                    <TableCell key={i} align="center">
                      <Radio
                        checked={
                          obj.staging_pathological_details[0].ki67 === item
                        }
                        //  disabled={editedObj}
                        onChange={(e) => handleChangeRadioBox(e)}
                        value={item}
                        name="ki67"
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </>
  );
};

export default StagingPathologicalDetailsForm;
