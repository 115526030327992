import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FeAutocomplete } from "../../../FormElements";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Checkbox from "@mui/material/Checkbox";
import { updateObjectValue } from "../../../../utils/helper";

import { isEmpty } from "lodash";
import adminAPi from "../../../../utils/adminAPi";
import FeTextArea from "../../../FormElements/FeTextArea";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ChemoModDetails = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    chemotherapy_modalities: [
      {
        detail: "",
      },
    ],
  });

  const [chemoModList, setChemoModList] = useState([]);
  const [modValue, setModValue] = useState([]);

  useEffect(() => {
    getHistopathologies();

    return () => {};
  }, []);

  const getHistopathologies = async () => {
    const { data } = await adminAPi.get(
      "admin/chemotherapy-modality-records-all"
    );
    setChemoModList(data.data);
  };

  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };
  function updateObject(firstObj, secondObj) {
    Object.keys(firstObj).forEach((key) => {
      if (secondObj.hasOwnProperty(key)) {
        firstObj[key] = secondObj[key];
      }
    });
    return firstObj;
  }

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);
  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObject(obj, updatedObj);
    }
    if (editedObj) {
      e = updateObject(obj, editedObj);
    }

    if (u) {
      setTimeout(() => {
        setModValue(
          updatedObj &&
            updatedObj.chemotherapy_modalities &&
            updatedObj.chemotherapy_modalities.length > 0
            ? updatedObj.chemotherapy_modalities?.map((o) => o.detail)
            : []
        );
        setObj(u);
      }, 100);
      return;
    } else if (!u && e) {
      setTimeout(() => {
        setModValue(editedObj?.chemotherapy_modalities?.map((o) => o.detail));
        setObj(e);
      }, 100);
    }
  }, [updatedObj, editedObj]);

  return (
    <>
      {" "}
      <Grid item xs={6} md={6}>
        <Box mb={1}>
          <label className="form-label">Treatment Modality</label>
          <FeAutocomplete
            multiple
            disableCloseOnSelect
            //  disabled={editedObj}
            value={modValue && modValue.length > 0 ? modValue : []}
            onChange={(e, v) => {
              handleChangeInput(
                "chemotherapy_modalities",
                v.map((o) => {
                  return { detail: o };
                })
              );
              console.log("VVVV", v);
              setModValue(v);
            }}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              );
            }}
            options={chemoModList.map((o) => o.name)}
            id="Histopathology"
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={6}></Grid>
    </>
  );
};

export default ChemoModDetails;
