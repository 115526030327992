import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import doctorAPI from "../../../utils/doctorAPI";
import { FeAutocomplete } from "../../FormElements";
import FeTextField from "../../FormElements/FeTextField";

const DrrFormDoctor = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({
    group: "",
    value: "",
  });

  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);

  async function getDetails(id) {
    const { data } = await doctorAPI.get(
      `doctor/doctor-recognition-records/${id}`
    );
    setFormValue(data.data);
  }

  const handleField = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        const { data } = await doctorAPI.put(
          `doctor/doctor-recognition-records/${id}`,
          formValue
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      } else {
        const { data } = await doctorAPI.post(
          "doctor/doctor-recognition-records",
          formValue
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <FeAutocomplete
              name="group"
              id="group"
              label="Group"
              value={formValue.group}
              options={["institution", "designation", "degree"]}
              onChange={(event, v) => {
                const name = "group";
                const value = v;
                let e = { target: { name, value } };
                handleField(e);
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <FeTextField
              name="value"
              id="value"
              label="Value"
              value={formValue.value}
              onChange={handleField}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
export default DrrFormDoctor;
