import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import Pagination from "@mui/material/Pagination";
import { FeAutocomplete, FeTextField } from "../../FormElements";
import { debounce } from "lodash";

const ResponseRateList = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [list, setlist] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
    type: "Clinical",
  });
  const options = [
    {
      label: "RECIST 1.1",
      value: "Clinical",
    },
    {
      label: "iRECIST",
      value: "Radiological",
    },
    {
      label: "Pathological",
      value: "Pathological",
    },
  ];
  const [type, setType] = useState(options[0]);

  useEffect(() => {
    getList();
    return () => {};
  }, [params]);

  const getList = async () => {
    try {
      const { data } = await adminAPi.get(
        "admin/response-rate-records",
        params
      );
      console.log("SSS", data);
      setlist(data.data.data);
      setTotalPage(data.data.last_page);
      setPage(data.data.current_page);
    } catch (error) {
      console.log("EE", error.response);
    }
  };

  const deleteItem = async (id) => {
    try {
      const { data } = await adminAPi.post(`admin/response-rate-records/${id}`);
      getList();
    } catch (error) {
      console.log("EE", error.response);
    }
  };
  const handleSearch = debounce((value) => {
    setParams({ ...params, search: value });
  }, 500);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" m={1}>
        <FeTextField
          onChange={(e) => handleSearch(e.target.value)}
          label="Search"
        />
        <Button onClick={() => navigate(`${pathname}/add`)} variant="contained">
          Add
        </Button>
      </Box>
      <Grid container>
        <Grid item xs={6} md={4}>
          <FeAutocomplete
            getOptionLabel={(option) => option.label}
            onChange={(e, v) => {
              // handleChangeInput("gender", v);
              setType(v);
              setParams({ ...params, type: v.value });
            }}
            value={type}
            options={options}
            disableClearable
            id="Sex"
            label="Type"
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Group</TableCell>
              <TableCell>Value</TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{row.group}</TableCell>
                  <TableCell>{row.value}</TableCell>

                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => navigate(`${pathname}/${row.id}`)}
                      >
                        View
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => deleteItem(row.id)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Pagination
          count={totalPage}
          page={page}
          onChange={(e, v) => {
            setPage(v);
            setParams({ ...params, page: v });
          }}
        />
      </TableContainer>
    </>
  );
};

export default ResponseRateList;
