import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { FeTextField, FeAutocomplete } from "../../../FormElements";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import doctorAPI from "../../../../utils/doctorAPI";
import { isEmpty } from "lodash";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LineofTreatment = ({ onStateChange, editedObj }) => {
  const [obj, setObj] = useState({
    line_of_treatment: "",
  });

  const [radioList, setRadioList] = useState([]);
  const [modValue, setModValue] = useState([]);
  useEffect(() => {
    if (editedObj) {
      setObj(Object.assign({}, obj, editedObj));
    }
  }, [editedObj]);

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);
  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };
  return (
    <>
      {" "}
      {/* <Grid item xs={6} md={6} mb={1}>
        <FeAutocomplete
          options={[
            "1st Line",
            "Continuous Maintenance",
            "Switch Maintenance",
            "2nd Line",
            "3rd Line",
            "4th Line",
            "5th Line",
          ]}
          id="LOT"
          label="Line of Treatment (LOT)"
        //  disabled={editedObj}
          onChange={(e, v) => {
            handleChangeInput("line_of_treatment", v);
          }}
        />
      </Grid> */}
    </>
  );
};

export default LineofTreatment;
