import React from "react";
import Plot from "react-plotly.js";
var x = [];

for (var i = 0; i < 500; i++) {
  x[i] = Math.random();
}

const Summary = () => {
  return (
    <div>
      {" "}
      <Plot
        data={[
          {
            x: x,
            type: "histogram",
          },
        ]}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%" }}
        config={{ responsive: true }}
      />
    </div>
  );
};

export default Summary;
