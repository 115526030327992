import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import FeTextField from "../../FormElements/FeTextField";

const CrrForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [formValue, setFormValue] = useState({
    name: "",
  });

  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);

  async function getDetails(id) {
    const { data } = await adminAPi.get(
      `admin/clinical-response-rate-records/${id}`
    );
    setFormValue(data.data);
  }

  const handleField = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        const { data } = await adminAPi.put(
          `admin/clinical-response-rate-records/${id}`,
          formValue
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      } else {
        const { data } = await adminAPi.post(
          "admin/clinical-response-rate-records",
          formValue
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <FeTextField
              name="name"
              id="name"
              label="Name"
              value={formValue.name}
              onChange={handleField}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
export default CrrForm;
