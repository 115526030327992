import * as React from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PatientTab from "../../components/PatientTab";

function createData(id, name, email, phone, address, action) {
  return { id, name, email, phone, address, action };
}

const rows = [
  createData(
    "01",
    "Santiago J. Schmidt",
    "SantiagoJSchmidt@teleworm.us",
    "573-730-6603",
    "2797 John Daniel Drive Cape Girardeau, MO 63701",
    <Box>
      <IconButton aria-label="View Details">
        <RemoveRedEyeOutlinedIcon />
      </IconButton>
      <IconButton aria-label="Edit">
        <ModeEditOutlineOutlinedIcon />
      </IconButton>
      <IconButton aria-label="Delete">
        <DeleteOutlineOutlinedIcon />
      </IconButton>
    </Box>
  ),
  createData(
    "02",
    "Santiago J. Schmidt",
    "SantiagoJSchmidt@teleworm.us",
    "573-730-6603",
    "2797 John Daniel Drive Cape Girardeau, MO 63701",
    <Box>
      <IconButton aria-label="View Details">
        <RemoveRedEyeOutlinedIcon />
      </IconButton>
      <IconButton aria-label="Edit">
        <ModeEditOutlineOutlinedIcon />
      </IconButton>
      <IconButton aria-label="Delete">
        <DeleteOutlineOutlinedIcon />
      </IconButton>
    </Box>
  ),
  createData(
    "03",
    "Santiago J. Schmidt",
    "SantiagoJSchmidt@teleworm.us",
    "573-730-6603",
    "2797 John Daniel Drive Cape Girardeau, MO 63701",
    <Box>
      <IconButton aria-label="View Details">
        <RemoveRedEyeOutlinedIcon />
      </IconButton>
      <IconButton aria-label="Edit">
        <ModeEditOutlineOutlinedIcon />
      </IconButton>
      <IconButton aria-label="Delete">
        <DeleteOutlineOutlinedIcon />
      </IconButton>
    </Box>
  ),
  createData(
    "04",
    "Santiago J. Schmidt",
    "SantiagoJSchmidt@teleworm.us",
    "573-730-6603",
    "2797 John Daniel Drive Cape Girardeau, MO 63701",
    <Box>
      <IconButton aria-label="View Details">
        <RemoveRedEyeOutlinedIcon />
      </IconButton>
      <IconButton aria-label="Edit">
        <ModeEditOutlineOutlinedIcon />
      </IconButton>
      <IconButton aria-label="Delete">
        <DeleteOutlineOutlinedIcon />
      </IconButton>
    </Box>
  ),
  createData(
    "05",
    "Santiago J. Schmidt",
    "SantiagoJSchmidt@teleworm.us",
    "573-730-6603",
    "2797 John Daniel Drive Cape Girardeau, MO 63701",
    <Box>
      <IconButton aria-label="View Details">
        <RemoveRedEyeOutlinedIcon />
      </IconButton>
      <IconButton aria-label="Edit">
        <ModeEditOutlineOutlinedIcon />
      </IconButton>
      <IconButton aria-label="Delete">
        <DeleteOutlineOutlinedIcon />
      </IconButton>
    </Box>
  ),
  createData(
    "06",
    "Santiago J. Schmidt",
    "SantiagoJSchmidt@teleworm.us",
    "573-730-6603",
    "2797 John Daniel Drive Cape Girardeau, MO 63701",
    <Box>
      <IconButton aria-label="View Details">
        <RemoveRedEyeOutlinedIcon />
      </IconButton>
      <IconButton aria-label="Edit">
        <ModeEditOutlineOutlinedIcon />
      </IconButton>
      <IconButton aria-label="Delete">
        <DeleteOutlineOutlinedIcon />
      </IconButton>
    </Box>
  ),
];

const Patient = () => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mx={-3}
        mt={-3}
        bgcolor="#fff"
        px={3}
        py={1}
      >
        <Typography variant="h6">Patients</Typography>
        {/* <Box>
          <TextField size="small" label="Search..." variant="outlined" />
        </Box> */}
      </Box>
      {/* <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Phone</TableCell>
              <TableCell align="right">Address</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.phone}</TableCell>
                <TableCell align="right">{row.address}</TableCell>
                <TableCell align="right">{row.action}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      <Box>
        <PatientTab />
      </Box>
    </>
  );
};
export default Patient;
