import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import ChemoCyclePreview from "./ChemoCyclePreview";
import adminAPi from "../../utils/adminAPi";

const PreviewPage = ({ obj = {}, docValue = null, ihcsRows }) => {
  return (
    <Box>
      <Box sx={{ border: "1px solid #f1f1f1" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box>
              Patient name:{" "}
              <span style={{ fontWeight: "bold" }}>{obj?.name}</span>{" "}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              Registration No.{" "}
              {obj.registration_no && (
                <span style={{ fontWeight: "bold" }}>
                  {obj?.registration_no}
                </span>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              Phone: <span style={{ fontWeight: "bold" }}>{obj?.phone}</span>{" "}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              Consultant:{" "}
              <span style={{ fontWeight: "bold" }}>
                {docValue ? docValue.name : obj?.doctor_patient?.doctor?.name}
              </span>{" "}
            </Box>
          </Grid>
        </Grid>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12} md={12}>
            <h3>Diagnosis:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Diagnosis Disease Group</TableCell>
                    <TableCell>Diagnosis Subgroup</TableCell>
                    <TableCell>Primary site</TableCell>
                    <TableCell>Metastatic Site</TableCell>
                    <TableCell>Laterality</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{obj.diagnosis_disease_group}</TableCell>
                    <TableCell>{obj.diagnosis_subgroup}</TableCell>
                    <TableCell>{obj.diagnosis_primary_site}</TableCell>
                    <TableCell>
                      {obj.diagnosis_metastatic_sites?.length > 0 &&
                        obj.diagnosis_metastatic_sites.map((item, i) => (
                          <p
                            style={{
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                              paddingBottom: "10px",
                            }}
                            key={i}
                          >
                            {" "}
                            {item.value}
                          </p>
                        ))}
                    </TableCell>

                    <TableCell>{obj.laterality}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={7}>
            <h3>Histopathology:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Histopathology Report</TableCell>
                    <TableCell>Grade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {obj.histopathologies?.length > 0 &&
                        obj.histopathologies.map((item, i) => (
                          <p
                            style={{
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                              paddingBottom: "10px",
                            }}
                            key={i}
                          >
                            {" "}
                            {item.detail}
                          </p>
                        ))}
                    </TableCell>

                    <TableCell>{obj.grade}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12}>
              <h3>TNM Staging (Clinical) :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>T</TableCell>
                      <TableCell>N</TableCell>
                      <TableCell>M</TableCell>
                      <TableCell>Stage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.staging_clinicals?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.t}</TableCell>
                        <TableCell>{row.n}</TableCell>
                        <TableCell>{row.m}</TableCell>
                        <TableCell>{row.result}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <h3>TNM Staging (Pathological) :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>T</TableCell>
                      <TableCell>N</TableCell>
                      <TableCell>M</TableCell>
                      <TableCell>Stage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.staging_pathologicals?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.t}</TableCell>
                        <TableCell>{row.n}</TableCell>
                        <TableCell>{row.m}</TableCell>
                        <TableCell>{row.result}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {obj?.patient_ihc_observation_details?.length > 0 &&
              obj?.patient_ihc_observation_details.map((obj, index) => (
                <Grid item xs={12} md={12}>
                  <h3>IHC Cycle : {index + 1} </h3>
                  <h4>
                    Date : {obj.date && dayjs(obj.date).format("DD-MM-YYYY")}{" "}
                  </h4>
                  <Grid item xs={12} md={6}>
                    <h3>IHC :</h3>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {ihcsRows &&
                              ihcsRows.map((row) => (
                                <TableCell>{row}</TableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {obj?.ihcs?.map((data, index) => (
                            <TableRow key={data.id}>
                              {ihcsRows &&
                                ihcsRows
                                  .map((v) => v.toLowerCase())
                                  .map((row) => (
                                    <TableCell>{data[row]}</TableCell>
                                  ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <h3>Pathological Staging - Details :</h3>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>lvsi</TableCell>
                            <TableCell>pni</TableCell>
                            <TableCell>margin</TableCell>
                            <TableCell>ki67</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {obj?.staging_pathological_details?.map(
                            (data, index) => (
                              <TableRow key={data.id}>
                                <TableCell>{data.lvsi}</TableCell>
                                <TableCell>{data.pni}</TableCell>
                                <TableCell>{data.margin}</TableCell>
                                <TableCell>{data.ki67}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              ))}
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12}>
              <h3>Molecular Pathology :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Method</TableCell>
                      <TableCell>Gene</TableCell>
                      <TableCell>Exon</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.molecular_pathologies?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {" "}
                          {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{row.method}</TableCell>
                        <TableCell>{row.gene}</TableCell>
                        <TableCell>{row.exon}</TableCell>
                        <TableCell>{row.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <h3>Cancer Marker :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Unit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.cancer_markers?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {" "}
                          {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.value}</TableCell>
                        <TableCell>{row.unit}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {obj &&
            obj.patient_observation_details &&
            obj.patient_observation_details.length > 0 &&
            obj.patient_observation_details.map((item, i) => (
              <ChemoCyclePreview cycle_no={i + 1} obj={item} key={i} />
            ))}

          <Grid item xs={12} md={6}>
            <h3>Surgery :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Surgery Date</TableCell>
                    <TableCell>Modality</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.surgeries?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {" "}
                        {row.surgery_date &&
                          dayjs(row.surgery_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>{row.modality}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Radiotherapy Schedules :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Intent</TableCell>
                    <TableCell>Modality</TableCell>
                    <TableCell>Fraction</TableCell>
                    <TableCell>Fraction Number</TableCell>
                    <TableCell>Total Dose</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.radiotherapy_schedules?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {" "}
                        {row.start_date &&
                          dayjs(row.start_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {row.end_date &&
                          dayjs(row.end_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>{row.intent}</TableCell>
                      <TableCell>
                        {row.modalities?.length > 0 &&
                          row.modalities
                            .map((item, i) => item.value)
                            .join(" , ")}
                      </TableCell>
                      <TableCell>{row.fraction}</TableCell>
                      <TableCell>{row.fraction_number}</TableCell>
                      <TableCell>{row.total_dose}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PreviewPage;
