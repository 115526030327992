const FeTextArea = ({ label, ...params }) => {
  return (
    <>
      <label className="form-label">{label}</label>
      <textarea
        // placeholder={label}
        className="form-control"
        rows="2"
        {...params}
      ></textarea>
    </>
  );
  // return <TextField fullWidth variant="outlined" {...params} />;
};
export default FeTextArea;
