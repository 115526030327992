import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormLabel,
  Grid,
  Radio,
  TableContainer,
  TableHead,
} from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import { FeTextField, FeAutocomplete } from "../../../FormElements";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";
import adminAPi from "../../../../utils/adminAPi";
import { isEmpty, reject } from "lodash";
import { updateObjectValue } from "../../../../utils/helper";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ClockStagingPathoForm = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    clock_positions: [],
  });

  useEffect(() => {
    if (updatedObj) {
      const u = updateObjectValue(obj, updatedObj);
      setTimeout(() => {
        setObj(u);
      }, 100);
    }
  }, [updatedObj]);
  useEffect(() => {
    if (editedObj) {
      setObj(Object.assign({}, obj, editedObj));
    }
  }, [editedObj]);

  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };

  const handleChangeCheckBox = (e, name, value) => {
    const { checked, id } = e.target;
    let clockObj = {
      side: name,
      position: value,
    };
    if (checked)
      setObj({
        ...obj,
        clock_positions: [...obj.clock_positions, clockObj],
      });
    else {
      setObj({
        ...obj,
        clock_positions: reject(obj.clock_positions, clockObj),
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);
  return (
    <>
      {" "}
      <Grid item xs={12} md={12}>
        <Box mb={2}>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.23)",
            }}
          >
            <Box py={1.5} pl={2}>
              <FormLabel>Clock Position (USG/Mammogram based)</FormLabel>
            </Box>
            <Table
              size="small"
              sx={{
                borderTop: "1px solid rgba(0, 0, 0, 0.23)",
                "& tr": {
                  "&:last-child": {
                    "& td, & th": {
                      borderBottom: 0,
                    },
                  },
                },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">1</TableCell>
                  <TableCell align="center">2</TableCell>
                  <TableCell align="center">3</TableCell>
                  <TableCell align="center">4</TableCell>
                  <TableCell align="center">5</TableCell>
                  <TableCell align="center">6</TableCell>
                  <TableCell align="center">7</TableCell>
                  <TableCell align="center">8</TableCell>
                  <TableCell align="center">9</TableCell>
                  <TableCell align="center">10</TableCell>
                  <TableCell align="center">11</TableCell>
                  <TableCell align="center">12</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Left
                  </TableCell>

                  {[...Array(12).keys()]
                    .map((x) => ++x)
                    .map((item, i) => (
                      <TableCell key={i} align="center">
                        <Checkbox
                          //  disabled={editedObj}
                          id={`Left_${item}`}
                          checked={obj.clock_positions
                            .filter((o) => o.side === "Left")
                            .map((o) => o.position)
                            .includes(item)}
                          value={i}
                          onChange={(e) =>
                            handleChangeCheckBox(e, "Left", item)
                          }
                        />
                      </TableCell>
                    ))}
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Right
                  </TableCell>
                  {[...Array(12).keys()]
                    .map((x) => ++x)
                    .map((item, i) => (
                      <TableCell key={i} align="center">
                        <Checkbox
                          //  disabled={editedObj}
                          id={`Right_${item}`}
                          checked={obj.clock_positions
                            .filter((o) => o.side === "Right")
                            .map((o) => o.position)
                            .includes(item)}
                          value={i}
                          onChange={(e) =>
                            handleChangeCheckBox(e, "Right", item)
                          }
                        />
                      </TableCell>
                    ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}></Grid>
    </>
  );
};

export default ClockStagingPathoForm;
