import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useRef, useState } from "react";
import { FeAutocomplete, FeTextField } from "../../../FormElements";
import { withStyles, makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import { debounce, isEmpty, merge, mergeWith, omit } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import adminAPi from "../../../../utils/adminAPi";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import ChemoModDetails from "./ChemoModDetails";
import ChemoProtoDateCycle from "./ChemoProtoDateCycle";
import ChemotherapyProtocolForm from "./ChemotherapyProtocolForm";
import ClockStagingPathoForm from "./ClockStagingPathoForm";
import DiagnosisForm from "./DiagnosisForm";
import GradeForm from "./GradeForm";
import HispathoStagingClinicalForm from "./HispathoStagingClinicalForm";

import RadioTherapyForm from "./RadioTherapyForm";

import SurgeryChemoDetails from "./SurgeryChemoDetails";
import PatientDemography from "./PatientDemography";
import StatForm from "./StatForm";
import DiagGroupCalc from "./DiagGroupCalc";
import TNMStagingClinical from "./TNMStagingClinical";
import TNMStagingPathological from "./TNMStagingPathological";
import DiagnosisDiseases from "./DiagnosisDiseases";
import ClinicalResponseRate from "./ClinicalResponseRate";
import RadiologicalResponseRate from "./RadiologicalResponseRate";
import PathologicalResponseRate from "./PathologicalResponseRate";
import CancerMarker from "./CancerMarker";
import LineofTreatment from "./LineofTreatment";
import ChemoDetails from "./ChemDetails";
import MolecularForm from "./MolecularForm";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PreviewPage from "../../../PreviewPage";
import {
  checkEmptyDetailsFromArray,
  hasNonNullValue,
} from "../../../../utils/helper";
import ChemoCycle from "./ChemoCycle";
import IHCCycle from "./IHCCycle";
import { Close } from "@mui/icons-material";
import StagingCycle from "./StagingCycle";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const patientTypeOptions = ["Primary", "Foreign"];
const sexOptions = ["Male", "Female", "Others"];

const lateralityOptions = ["Right", "Left", "B/Lateral"];

const initialObj = {
  new_patient: true,
  cancer_type: "Breast",
  date: new Date(),
  name: "",
  phone: "",
  gender: "",
  date_of_birth: null,
  first_diagnosis_date: null,

  doctor_id: null,

  laterality: "",
  registration_no: null,
};
function getSteps() {
  return ["Patient Demography", "Diagnosis", "Treatment"];
}
const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "380px",
    backgroundColor: "var(--light-background)",
    color: "var(--dark-text)",
  },
  root: {
    width: "100%",
  },
  backButton: {
    // marginRight: theme.spacing(1),
    borderRadius: "0",
  },
  stepper: {
    // marginRight: theme.spacing(1),
    paddingBottom: "3rem",
  },
  nextButton: {
    borderRadius: "0",
    color: "white",
    background: "#0f2731",
    "&:hover": {
      backgroundImage: "linear-gradient(#8d0126, #230615);",
    },
  },
  icon: {
    fill: "#0f2731",
  },
  text: {
    fill: "white",
  },
  completed: {
    fill: "green",
  },
  instructions: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
}));
const PoFormBreast = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(dayjs("2014-08-18T21:11:54"));
  const steps = getSteps();
  const [obj, setObj] = useState(initialObj);
  const [editedObj, setEditedObj] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showChemo, setShowChemo] = useState(false);
  const [editingChemo, setEditingChemo] = useState(false);
  const [showIHCCycle, setShowIHC] = useState(false);
  const [editingIHC, setEditingIHC] = useState(false);
  const [showStagingCycle, setShowStagingCycle] = useState(false);
  const [editingStaging, setEditingStaging] = useState(false);
  const [previewObj, setPreviewObj] = useState({});
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  const [docList, setDocList] = useState([]);
  const [docValue, setDocValue] = useState({});
  const [docParams, setDocParams] = useState({
    per_page: 15,
    page: 1,
  });
  const [centerList, setCenterList] = useState([]);
  const [centerValue, setCenterValue] = useState({});
  const [centerParams, setCenterParams] = useState({
    per_page: 15,
    page: 1,
  });
  const [patientList, setPatientList] = useState([]);

  const [patientValue, setPatientValue] = useState({});
  const [patientParams, setPatientParams] = useState({
    per_page: 15,
    page: 1,
  });

  const [comoList, setComoList] = useState([]);
  const [comValue, setComValue] = useState([]);
  const [diagGroupList, setDiagGroupList] = useState([]);
  const [diagGroupValue, setDiagGroupValue] = useState([]);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const [ihcsRows, setIhcsRows] = useState([]);

  useEffect(() => {
    getIhcsRows();
  }, []);
  const getIhcsRows = async () => {
    const { data } = await adminAPi.get("admin/ihc-records-all");

    const names = data.data.map((d) => d.name);
    setIhcsRows(names);
    // const result = createIHCObject(names);
    // setObj(result);
    // setObj({ihcs:names.map((n)=>({name:n}))});
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleStep = (step) => {
    setActiveStep(step);
  };
  useEffect(() => {
    getComodities();
    getDiagGroups();
    return () => {};
  }, []);

  const getComodities = async () => {
    const { data } = await adminAPi.get("admin/comorbidity-records-all");
    setComoList(data.data);
  };

  const getDiagGroups = async () => {
    const { data } = await adminAPi.get("admin/diagnosis-group-records", {
      per_page: 50,
      page: 1,
    });
    setDiagGroupList(data.data.data);
  };
  useEffect(() => {
    getDoc();
    return () => {};
  }, [docParams]);

  const getDoc = async () => {
    const { data } = await adminAPi.get("admin/doctors", docParams);
    setDocList(data.data.data);
  };

  // useEffect(() => {
  //   getCenter();
  //   return () => {};
  // }, [centerParams]);

  const getCenter = async () => {
    const { data } = await adminAPi.get("admin/centers", centerParams);
    setCenterList(data.data.data);
  };
  useEffect(() => {
    getPatientList();
    return () => {};
  }, [patientParams]);

  const getPatientList = async () => {
    const { data } = await adminAPi.get("admin/patients", patientParams);
    setPatientList(data.data.data);
  };

  const handleOnInputCapturePatient = debounce((event) => {
    setPatientParams({ ...patientParams, phone: event });
  }, 500);
  const handleOnInputCaptureCenter = debounce((event) => {
    setCenterParams({ ...centerParams, name: event });
  }, 500);
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleOnInputCaptureDoctor = debounce((event) => {
    setDocParams({ ...docParams, name: event });
  }, 500);
  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };
  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);

  async function getDetails(id) {
    const { data } = await adminAPi.get(`admin/patient-observations/${id}`);
    setObj(data.data);
    setObj({ ...data.data, ...data.data.patient });

    setPatientValue(data.data.patient);
    setPatientList([data.data.patient]);
    setEditedObj(data.data);
    setComValue(data.data.comorbidities);

    setDocValue(data.data.doctor);
  }

  const DiagnosisFormRef = useRef(null);

  const ClockStagingPathoFormRef = useRef(null);
  const GradeFormRef = useRef(null);
  const HispathoStagingClinicalFormRef = useRef(null);

  const RadioTherapyFormRef = useRef(null);

  const SurgeryChemoDetailsFormRef = useRef(null);
  const DemographyFormRef = useRef(null);

  const TNMStagingPathologicalRef = useRef(null);
  const TNMStagingClinicalRef = useRef(null);

  const DiagDiseaseRef = useRef(null);
  const CancerMarkerRef = useRef(null);
  const MoleCularRef = useRef(null);
  const DiagGroupRef = useRef(null);
  const chemoArr = useRef(null);
  const IHCArr = useRef(null);
  const StagingArr = useRef(null);

  console.log("chemoArr", chemoArr && chemoArr.current);

  const handleSubmit = async (e, formValue) => {
    e.preventDefault();
    setShowError(false);
    setBackendErrorMessage({});
    try {
      if (id) {
        // const { data } = await adminAPi.put(
        //   `admin/patient-observations/${id}`,
        //   formValue
        // );
        // navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      } else {
        try {
          const { data } = await adminAPi.post(
            "admin/patient-observations",
            formValue
          );
          setTimeout(() => {
            setObj({
              new_patient: true,
              cancer_type: "Breast",
              date: new Date(),
              name: "",
              phone: "",
              gender: "",
              date_of_birth: null,
              first_diagnosis_date: null,

              doctor_id: null,

              laterality: "",
              registration_no: null,
            });
          }, 200);

          setDocValue({});
          setPatientValue({});

          setEditedObj(null);
          setComValue([]);

          DiagnosisFormRef.current = null;

          ClockStagingPathoFormRef.current = null;
          GradeFormRef.current = null;
          HispathoStagingClinicalFormRef.current = null;

          RadioTherapyFormRef.current = null;

          SurgeryChemoDetailsFormRef.current = null;
          DemographyFormRef.current = null;

          TNMStagingPathologicalRef.current = null;
          TNMStagingClinicalRef.current = null;

          DiagDiseaseRef.current = null;
          CancerMarkerRef.current = null;
          MoleCularRef.current = null;
          DiagGroupRef.current = null;
          chemoArr.current = null;
          IHCArr.current = null;
          StagingArr.current = null;
          navigate("/admin/patient-observations");
        } catch (error) {
          console.log("aa1", error.response);

          if (error.response.data.errors !== undefined) {
            setShowError(true);
            setBackendErrorMessage(error.response.data.errors);
          }
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            alert(error.response.data.message);
          }
          // const inputString = obj.registration_no;
          // if (inputString && docValue) {
          //   let objj = inputString.replace(`${docValue?.center?.name} - `, "");
          //   setObj({
          //     ...obj,
          //     registration_no: objj,
          //   });
          // }
        }
      }
    } catch (error) {
      console.log("aa", error.response);
      if (error.response && error.response.data) {
        alert(error.response.data.message);
      }
    }
  };

  console.log("obj", obj);
  function cleanObject(obj) {
    if (Array.isArray(obj)) {
      return obj
        .map((item) => cleanObject(item)) // Recursively clean each object in the array
        .filter(
          (item) =>
            Object.values(item).some((value) => value !== null && value !== "") // Keep object if at least one value is not empty or null
        );
    } else if (obj !== null && typeof obj === "object") {
      return Object.keys(obj).reduce((acc, key) => {
        const value = cleanObject(obj[key]);

        // Check if value is not null or empty and not an empty object/array
        if (
          value !== null &&
          value !== "" &&
          (typeof value !== "object" || Object.keys(value).length > 0)
        ) {
          acc[key] = value;
        }

        return acc;
      }, {});
    }

    return obj; // Return primitive values (non-object/non-array)
  }

  function deepMerge(target, ...sources) {
    if (!sources.length) return target;

    const source = sources.shift();

    if (typeof target === "object" && typeof source === "object") {
      for (const key in source) {
        if (source.hasOwnProperty(key)) {
          if (Array.isArray(source[key])) {
            // If it's an array, replace the array in target with the one in source
            target[key] = source[key];
          } else if (typeof source[key] === "object") {
            // If it's an object, recurse
            if (!target[key]) Object.assign(target, { [key]: {} });
            deepMerge(target[key], source[key]);
          } else {
            // Primitive value or key, just replace it
            target[key] = source[key];
          }
        }
      }
    }

    return deepMerge(target, ...sources);
  }
  return (
    <Card sx={{ height: "100%!important" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CardContent>
          <Box component="form">
            <Box width="100%">
              <Stepper
                activeStep={activeStep}
                nonLinear
                className={classes.stepper}
              >
                {steps.map((label, i) => (
                  <Step key={label}>
                    <StepButton color="inherit" onClick={() => handleStep(i)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <Box>
                <Grid container spacing={2}>
                  {activeStep === 0 && (
                    <>
                      <Grid item xs={12} md={12}>
                        <Grid item xs={6} md={6}>
                          <Box mb={2}>
                            <FeDesktopDatePicker
                              //  disabled={editedObj}
                              label="Date"
                              inputFormat="DD/MM/YYYY"
                              value={obj.date}
                              onChange={(val) => {
                                handleChangeInput(
                                  "date",
                                  dayjs(val).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeAutocomplete
                            freeSolo
                            //  disabled={editedObj}
                            value={docValue}
                            options={docList}
                            onChange={async (e, v) => {
                              setDocValue(v);

                              const { data } = await adminAPi.post(
                                `admin/patient-observations-reg-no`,
                                { prefix: `${v?.center?.name} - ` }
                              );
                              setObj({
                                ...obj,
                                doctor_id: v.id,
                                registration_no: data?.data ? data.data : null,
                              });
                            }}
                            onInputChange={(e, v, r) => {
                              if (r === "input") {
                                handleOnInputCaptureDoctor(v);
                              } else if (r === "clear") {
                                setObj({ ...obj, doctor_id: null });
                                handleOnInputCaptureDoctor(v);
                              }
                            }}
                            getOptionLabel={(option) => {
                              return option.name || "";
                            }}
                            label="Consultant/ Primary Physician"
                          />
                        </Box>
                      </Grid>
                      {/* <Grid item xs={6} md={4}>
                  <Box mb={2}>
                    <FeAutocomplete
                      freeSolo
                      value={centerValue}
                    //  disabled={editedObj}
                      options={centerList}
                      onChange={(e, v) => {
                        setCenterValue(v);
                        handleChangeInput("center_id", v.id);
                      }}
                      onInputChange={(e, v, r) => {
                        if (r === "input") {
                          handleOnInputCaptureCenter(v);
                        } else if (r === "clear") {
                          setObj({ ...obj, doctor_id: null });
                          handleOnInputCaptureCenter(v);
                        }
                      }}
                      getOptionLabel={(option) => {
                        return option.name || "";
                      }}
                      label="Center Name"
                    />
                  </Box>
                </Grid> */}
                      <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeTextField
                            onBlur={(e) =>
                              handleChangeInput(
                                "registration_no",
                                e.target.value
                              )
                            }
                            //  disabled={editedObj}
                            defaultValue={obj.registration_no}
                            id="RegistrationNo"
                            label="Registration No"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {/* {docValue?.center?.name} - */}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeAutocomplete
                            freeSolo
                            //  disabled={editedObj}
                            value={patientValue}
                            options={patientList}
                            onChange={async (e, v) => {
                              setPatientValue(v);
                              const { data } = await adminAPi.get(
                                `admin/patients/${v.id}`
                              );

                              console.log("VVVVVVV", v);

                              if (
                                data?.data?.patient_observations?.length > 0
                              ) {
                                let history = null;
                                let lastObj =
                                  data.data.patient_observations[
                                    data.data.patient_observations.length - 1
                                  ];

                                if (lastObj?.patient_history) {
                                  history = lastObj?.patient_history;

                                  delete lastObj.doctor;
                                  delete lastObj.registration_no;
                                  delete lastObj.doctor_id;
                                }
                                // lastObj.patient_observation_details =
                                //   updateOBJ.patient_observation_details;

                                setEditedObj(lastObj);

                                if (
                                  lastObj.comorbidities &&
                                  lastObj.comorbidities.length > 0
                                ) {
                                  setComValue(
                                    lastObj.comorbidities.map((o) => o.detail)
                                  );
                                }
                                // if (
                                //   lastObj.chemotherapy_modalities &&
                                //   lastObj.chemotherapy_modalities.length > 0
                                // ) {
                                //   ChemoModDetailsRef.current =
                                //     lastObj.chemotherapy_modalities;
                                // }
                                if (history) {
                                  let merged = merge(obj, v, history);
                                  console.log("merged", merged);
                                  setObj(merged);
                                } else {
                                  setObj({ ...obj, ...v });
                                }
                              } else {
                                setObj({ ...obj, ...v });
                              }
                            }}
                            onBlur={(e) => {
                              handleChangeInput("phone", e.target.value);
                              console.log("false", e.target.value);
                            }}
                            onInputChange={(e, v, r) => {
                              if (r === "input") {
                                handleOnInputCapturePatient(v);
                              } else if (r === "clear") {
                                setObj(initialObj);

                                handleOnInputCapturePatient(v);
                              }
                            }}
                            getOptionLabel={(option) => {
                              return option.phone || "";
                            }}
                            //   id="ConsultantPrimaryPhisician"
                            label="Patient Contact / Mobile No"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeTextField
                            //  disabled={editedObj}
                            onBlur={(e) =>
                              handleChangeInput("name", e.target.value)
                            }
                            defaultValue={obj.name}
                            id="PatientName"
                            label="Patient Name"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box
                          sx={{
                            "& .MuiFormControl-root": {
                              width: "100%",
                            },
                          }}
                        >
                          <FeDesktopDatePicker
                            //  disabled={editedObj}
                            label="Date of Birth"
                            inputFormat="DD/MM/YYYY"
                            value={obj.date_of_birth}
                            onChange={(v) => {
                              const name = "date_of_birth";
                              const value = dayjs(v).format("YYYY-MM-DD");
                              handleChangeInput(name, value);
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeAutocomplete
                            onChange={(e, v) => {
                              handleChangeInput("gender", v);
                            }}
                            //  disabled={editedObj}
                            value={obj.gender}
                            options={sexOptions}
                            id="Sex"
                            label="Sex"
                          />
                        </Box>
                      </Grid>

                      <PatientDemography
                        pObj={obj}
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          DemographyFormRef.current = state;
                          // setUpdatedObj(state);
                        }}
                        updatedObj={DemographyFormRef.current}
                      />

                      <Grid item xs={6} md={4}>
                        <Box
                          sx={{
                            "& .MuiFormControl-root": {
                              width: "100%",
                            },
                          }}
                        >
                          <FeDesktopDatePicker
                            //  disabled={editedObj}
                            label="Date of 1st Diagnosis"
                            inputFormat="DD/MM/YYYY"
                            value={obj.first_diagnosis_date}
                            onChange={(v) => {
                              const name = "first_diagnosis_date";
                              const value = dayjs(v).format("YYYY-MM-DD");
                              handleChangeInput(name, value);
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box mb={2}>
                          <FeAutocomplete
                            multiple
                            //  disabled={editedObj}
                            disableCloseOnSelect
                            value={comValue}
                            onChange={(e, v) => {
                              handleChangeInput(
                                "comorbidities",
                                v.map((o) => {
                                  return { detail: o };
                                })
                              );

                              setComValue(v);
                            }}
                            renderOption={(props, option, { selected }) => {
                              return (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              );
                            }}
                            options={comoList.map((o) => o.name)}
                            id="Comorbidities"
                            label="Comorbidities"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.nextButton}
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Grid>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <DiagnosisDiseases
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          DiagDiseaseRef.current = state;
                        }}
                        updatedObj={DiagDiseaseRef.current}
                      />
                      <DiagnosisForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          DiagnosisFormRef.current = state;
                        }}
                        updatedObj={DiagnosisFormRef.current}
                      />
                      <HispathoStagingClinicalForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          HispathoStagingClinicalFormRef.current = state;
                        }}
                        updatedObj={HispathoStagingClinicalFormRef.current}
                      />
                      <GradeForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          GradeFormRef.current = state;
                        }}
                        updatedObj={GradeFormRef.current}
                      />

                      <Grid item xs={12} md={12}>
                        <Stack direction="row" spacing={2}>
                          <Button
                            onClick={() => {
                              setEditingIHC(false);
                              setShowIHC(true);
                            }}
                            variant="contained"
                            startIcon={<AddIcon />}
                          >
                            Add IHC Cycle
                          </Button>

                          {((IHCArr &&
                            IHCArr.current &&
                            IHCArr.current.ihcs?.length > 0) ||
                            (editedObj &&
                              editedObj.ihcs &&
                              editedObj.ihcs.length > 0)) && (
                            <Button
                              onClick={() => {
                                setEditingIHC(true);
                                setShowIHC(true);
                              }}
                              variant="outlined"
                              startIcon={<EditIcon />}
                            >
                              Edit IHC Cycles
                            </Button>
                          )}
                        </Stack>
                      </Grid>

                      <Dialog
                        sx={{
                          "& .MuiDialogContent-root": {
                            padding: "20px 24px 0",
                          },
                        }}
                        fullWidth
                        open={showIHCCycle}
                        onClose={(event, reason) => {
                          if (
                            reason &&
                            reason === "backdropClick" &&
                            "escapeKeyDown"
                          )
                            return;

                          setEditingIHC(false);
                          setShowIHC(false);
                        }}
                        maxWidth={"xl"}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          position={"relative"}
                          padding={"12px 20px 0px 20px"}
                          width={"100%"}
                          gap={"20px"}
                          alignSelf={"stretch"}
                        >
                          <Typography variant="h6" fontWeight={600}>
                            IHC Cycle
                          </Typography>

                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <IconButton
                              onClick={(e) => {
                                setEditingIHC(false);
                                setShowIHC(false);
                              }}
                              size="small"
                            >
                              <Close />
                            </IconButton>
                          </Box>
                        </Box>
                        <DialogContent>
                          <IHCCycle
                            prevSavedObj={editedObj}
                            editing={editingIHC}
                            updatedChemo={IHCArr.current}
                            setShowChemo={setShowIHC}
                            setChemoArray={(data) => {
                              console.log("AAAAA", data);
                              IHCArr.current = data;
                            }}
                          />
                        </DialogContent>
                      </Dialog>
                      <Grid item xs={12} md={12}>
                        <Stack direction="row" spacing={2}>
                          <Button
                            onClick={() => {
                              setEditingStaging(false);
                              setShowStagingCycle(true);
                            }}
                            variant="contained"
                            startIcon={<AddIcon />}
                          >
                            Add Staging Cycle
                          </Button>

                          {((StagingArr &&
                            StagingArr.current &&
                            StagingArr.current.staging_pathological_details
                              ?.length > 0) ||
                            (editedObj &&
                              editedObj.staging_pathological_details &&
                              editedObj.staging_pathological_details.length >
                                0)) && (
                            <Button
                              onClick={() => {
                                setEditingStaging(true);
                                setShowStagingCycle(true);
                              }}
                              variant="outlined"
                              startIcon={<EditIcon />}
                            >
                              Edit Staging Cycles
                            </Button>
                          )}
                        </Stack>
                      </Grid>
                      <Dialog
                        sx={{
                          "& .MuiDialogContent-root": {
                            padding: "20px 24px 0",
                          },
                        }}
                        fullWidth
                        open={showStagingCycle}
                        onClose={(event, reason) => {
                          if (
                            reason &&
                            reason === "backdropClick" &&
                            "escapeKeyDown"
                          )
                            return;
                          setEditingStaging(false);
                          setShowStagingCycle(false);
                        }}
                        maxWidth={"xl"}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          position={"relative"}
                          padding={"12px 20px 0px 20px"}
                          width={"100%"}
                          gap={"20px"}
                          alignSelf={"stretch"}
                        >
                          <Typography variant="h6" fontWeight={600}>
                            Staging Cycle
                          </Typography>

                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <IconButton
                              onClick={(e) => {
                                setEditingStaging(false);
                                setShowStagingCycle(false);
                              }}
                              size="small"
                            >
                              <Close />
                            </IconButton>
                          </Box>
                        </Box>
                        <DialogContent>
                          <StagingCycle
                            prevSavedObj={editedObj}
                            editing={editingStaging}
                            updatedChemo={StagingArr.current}
                            setShowChemo={setShowStagingCycle}
                            setChemoArray={(data) => {
                              console.log("AAAAA", data);
                              StagingArr.current = data;
                            }}
                          />
                        </DialogContent>
                      </Dialog>
                      <TNMStagingClinical
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          TNMStagingClinicalRef.current = state;
                        }}
                        updatedObj={TNMStagingClinicalRef.current}
                      />
                      <TNMStagingPathological
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          TNMStagingPathologicalRef.current = state;
                        }}
                        updatedObj={TNMStagingPathologicalRef.current}
                      />

                      <MolecularForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          console.log("TOPU", state);
                          MoleCularRef.current = state;
                        }}
                        updatedObj={MoleCularRef.current}
                      />
                      <CancerMarker
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          CancerMarkerRef.current = state;
                        }}
                        updatedObj={CancerMarkerRef.current}
                      />
                      <Grid item xs={6} md={6}></Grid>
                      <Grid item xs={12} md={12}>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.nextButton}
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Grid>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <Grid item xs={12} md={12}>
                        <Stack direction="row" spacing={2}>
                          <Button
                            onClick={() => {
                              setEditingChemo(false);
                              setShowChemo(true);
                            }}
                            variant="contained"
                            startIcon={<AddIcon />}
                          >
                            Add Treatment Cycle
                          </Button>

                          {((chemoArr &&
                            chemoArr.current &&
                            chemoArr.current.patient_observation_details
                              ?.length > 0) ||
                            (editedObj &&
                              editedObj.patient_observation_details &&
                              editedObj.patient_observation_details.length >
                                0)) && (
                            <Button
                              onClick={() => {
                                setEditingChemo(true);
                                setShowChemo(true);
                              }}
                              variant="outlined"
                              startIcon={<EditIcon />}
                            >
                              Edit Treatment Cycle
                            </Button>
                          )}
                        </Stack>
                      </Grid>
                      <Dialog
                        sx={{
                          "& .MuiDialogContent-root": {
                            padding: "20px 24px 0",
                          },
                        }}
                        fullWidth
                        open={showChemo}
                        onClose={(event, reason) => {
                          if (
                            reason &&
                            reason === "backdropClick" &&
                            "escapeKeyDown"
                          )
                            return;
                          setEditingChemo(false);
                          setShowChemo(false);
                        }}
                        maxWidth={"xl"}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          position={"relative"}
                          padding={"12px 20px 0px 20px"}
                          width={"100%"}
                          gap={"20px"}
                          alignSelf={"stretch"}
                        >
                          <Typography variant="h6" fontWeight={600}>
                            Treatment Cycle
                          </Typography>

                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <IconButton
                              onClick={(e) => {
                                setEditingChemo(false);
                                setShowChemo(false);
                              }}
                              size="small"
                            >
                              <Close />
                            </IconButton>
                          </Box>
                        </Box>
                        <DialogContent>
                          <ChemoCycle
                            prevSavedObj={editedObj}
                            editing={editingChemo}
                            updatedChemo={chemoArr.current}
                            setShowChemo={setShowChemo}
                            first_diagnosis_date={obj.first_diagnosis_date}
                            setChemoArray={(data) => {
                              chemoArr.current = data;
                            }}
                          />
                        </DialogContent>
                      </Dialog>

                      {/* {Cycle Starts} */}
                      {/* <ChemoModDetails
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          ChemoModDetailsRef.current = state;
                        }}
                        updatedObj={ChemoModDetailsRef.current}
                      />
                      <ChemoProtoDateCycle
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          ChemoProtoDateCycleRef.current = state;
                        }}
                        updatedObj={ChemoProtoDateCycleRef.current}
                      />

                      <ChemotherapyProtocolForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          ChemotherapyProtocolRef.current = state;
                        }}
                        updatedObj={ChemotherapyProtocolRef.current}
                      />
                      <ChemoDetails
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          ChemoDetailsRef.current = state;
                        }}
                        updatedObj={ChemoDetailsRef.current}
                      />

                      <Grid item xs={6} md={4}></Grid>

                      <ClinicalResponseRate
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          ClinicalResRateRef.current = state;
                        }}
                        updatedObj={ClinicalResRateRef.current}
                      />
                      <RadiologicalResponseRate
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          RadioResRateRef.current = state;
                        }}
                        updatedObj={RadioResRateRef.current}
                      />
                      <PathologicalResponseRate
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          PathoResRateRef.current = state;
                        }}
                        updatedObj={PathoResRateRef.current}
                      />
                      <StatForm
                        diagDate={obj.first_diagnosis_date}
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          StatFormRef.current = state;
                        }}
                        updatedObj={StatFormRef.current}
                      /> */}

                      {/* {Cycle Ends} */}
                      <SurgeryChemoDetails
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          SurgeryChemoDetailsFormRef.current = state;
                        }}
                        updatedObj={SurgeryChemoDetailsFormRef.current}
                      />
                      <RadioTherapyForm
                        editedObj={editedObj}
                        onStateChange={(state) => {
                          RadioTherapyFormRef.current = state;
                        }}
                        updatedObj={RadioTherapyFormRef.current}
                      />
                      <Grid item xs={12} md={12}>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          Back
                        </Button>
                        <Button
                          disabled={activeStep === 0}
                          onClick={() => {
                            setShowPreview(true);
                            console.log(
                              // ClockStagingPathoFormRef.current,
                              // DiagnosisFormRef.current,
                              JSON.stringify(GradeFormRef.current),
                              JSON.stringify(
                                HispathoStagingClinicalFormRef.current
                              ),

                              JSON.stringify(RadioTherapyFormRef.current),
                              JSON.stringify(DiagDiseaseRef.current)
                              // CancerMarkerRef.current,

                              // SurgeryChemoDetailsFormRef.current,
                              // DemographyFormRef.current,

                              // MoleCularRef.current
                            );
                            const cleanObj = cleanObject(obj);
                            let objtoPost1 = deepMerge(
                              cleanObj,
                              editedObj,
                              ClockStagingPathoFormRef.current,

                              HispathoStagingClinicalFormRef.current,

                              RadioTherapyFormRef.current,

                              CancerMarkerRef.current,

                              SurgeryChemoDetailsFormRef.current,
                              DemographyFormRef.current,

                              MoleCularRef.current
                            );

                            let chemoObj = {};

                            if (
                              editedObj?.patient_observation_details?.length >
                                0 &&
                              !chemoArr.current
                            ) {
                              chemoObj = {
                                patient_observation_details:
                                  editedObj?.patient_observation_details,
                              };
                            } else if (
                              chemoArr &&
                              chemoArr.current?.patient_observation_details
                                ?.length > 0
                            ) {
                              chemoObj = {
                                patient_observation_details:
                                  chemoArr.current?.patient_observation_details,
                              };
                            }
                            console.log("objtoPost1", chemoObj);
                            let objtoPost = {
                              ...objtoPost1,
                              ...DiagnosisFormRef.current,
                              ...GradeFormRef.current,
                              ...DiagDiseaseRef.current,

                              ...DiagGroupRef.current,
                              ...TNMStagingPathologicalRef.current,
                              ...TNMStagingClinicalRef.current,
                              ...IHCArr.current,
                              ...StagingArr.current,
                              ...chemoObj,
                            };
                            console.log("chemoOBJ", chemoObj);
                            // if (!isEmpty(docValue)) {
                            //   objtoPost.registration_no = `${docValue?.center?.name} - ${objtoPost.registration_no}`;
                            // }
                            if (!isEmpty(patientValue)) {
                              objtoPost.new_patient = false;
                            } else {
                              objtoPost.new_patient = true;
                            }

                            console.log("OBDJDJD", objtoPost);

                            setPreviewObj(objtoPost);
                          }}
                          variant="success"
                        >
                          Preview
                        </Button>
                        <Button
                          //  disabled={editedObj}
                          onClick={(e) => {
                            // console.log(
                            //   // ClockStagingPathoFormRef.current,
                            //   // DiagnosisFormRef.current,
                            //   JSON.stringify(GradeFormRef.current),
                            //   JSON.stringify(
                            //     HispathoStagingClinicalFormRef.current
                            //   ),

                            //   JSON.stringify(RadioTherapyFormRef.current),
                            //   JSON.stringify(DiagDiseaseRef.current)
                            //   // CancerMarkerRef.current,

                            //   // SurgeryChemoDetailsFormRef.current,
                            //   // DemographyFormRef.current,

                            //   // MoleCularRef.current
                            // );
                            const cleanObj = cleanObject(obj);
                            console.log("clean 1", editedObj);
                            let objtoPost1 = deepMerge(
                              cleanObj,
                              editedObj,

                              ClockStagingPathoFormRef.current,

                              HispathoStagingClinicalFormRef.current,

                              RadioTherapyFormRef.current,

                              CancerMarkerRef.current,

                              SurgeryChemoDetailsFormRef.current,
                              DemographyFormRef.current,

                              MoleCularRef.current
                            );
                            console.log("objtoPost1", objtoPost1);

                            let chemoObj = {};

                            if (
                              editedObj?.patient_observation_details?.length >
                                0 &&
                              !chemoArr.current
                            ) {
                              chemoObj = {
                                patient_observation_details:
                                  editedObj?.patient_observation_details,
                              };
                            } else if (
                              chemoArr &&
                              chemoArr.current?.patient_observation_details
                                ?.length > 0
                            ) {
                              chemoObj = {
                                patient_observation_details:
                                  chemoArr.current?.patient_observation_details,
                              };
                            }
                            console.log("chemoOBJ", chemoObj);

                            let objtoPost = {
                              ...objtoPost1,
                              ...DiagnosisFormRef.current,
                              ...GradeFormRef.current,
                              ...DiagDiseaseRef.current,

                              ...DiagGroupRef.current,
                              ...TNMStagingPathologicalRef.current,
                              ...TNMStagingClinicalRef.current,
                              ...IHCArr.current,
                              ...StagingArr.current,
                              ...chemoObj,
                            };
                            console.log("objtoPost", objtoPost);
                            const cleanedData = cleanObject(objtoPost);
                            console.log("cleanedData", cleanedData);

                            if (!isEmpty(patientValue)) {
                              objtoPost.new_patient = false;
                            } else {
                              objtoPost.new_patient = true;
                            }

                            if (objtoPost.doctor_id === null) {
                              alert("Please select a doctor");
                              return;
                            }
                            if (objtoPost.phone === "") {
                              alert("Please select a patient");
                              return;
                            }
                            // const cleanedData = cleanObject(objtoPost);

                            console.log("OBDJDJD", cleanedData);

                            handleSubmit(e, cleanedData);
                          }}
                          className={classes.nextButton}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Box>
            <Box width="100%" pr={2} pt={2}>
              <Box textAlign="right" maxWidth={760} mx="auto"></Box>
            </Box>
          </Box>
        </CardContent>
      </LocalizationProvider>{" "}
      <Dialog
        fullWidth
        open={showPreview}
        onClose={() => setShowPreview(false)}
        maxWidth={"xl"}
      >
        <DialogTitle>Preview</DialogTitle>
        <DialogContent>
          <PreviewPage obj={previewObj} ihcsRows={ihcsRows} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPreview(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={showError}
        onClose={() => setShowError(false)}
        maxWidth={"md"}
      >
        <DialogTitle>Errors</DialogTitle>
        <DialogContent>
          {Object.keys(backendErrorMessage).map((key) => (
            <p className="error-message">{backendErrorMessage[key]}</p>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowError(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default PoFormBreast;
