import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button, Checkbox, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FeAutocomplete } from "../../../FormElements";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { updateObjectValue } from "../../../../utils/helper";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import adminAPi from "../../../../utils/adminAPi";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SurgeryChemoDetails = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    surgeries: [
      {
        surgery_date: null,
        modality: "",
        surgical_laterality: [],
      },
    ],
  });

  const [modList, setModList] = useState([]);
  const [latList, setLatList] = useState([]);
  useEffect(() => {
    getLatList();

    return () => {};
  }, []);

  const getLatList = async () => {
    const { data } = await adminAPi.get(
      "admin/surgical-laterality-records-all"
    );
    setLatList(data.data);
  };
  useEffect(() => {
    getModList();
    return () => {};
  }, []);
  useEffect(() => {
    if (editedObj) {
      const e = updateObjectValue(obj, editedObj);
      if (e.surgeries.length > 0) {
        const surgeries = e.surgeries.map((item, i) => {
          let obj = {
            surgery_date: item.surgery_date,
            modality: item.modality,
          };
          if (item?.surgical_lateralities?.length > 0) {
            obj.surgical_laterality = item.surgical_lateralities.map(
              (innerItem, k) => {
                let innerObj = {
                  value: innerItem.value,
                };
                return innerObj;
              }
            );
          }
          return obj;
        });
        if (surgeries && surgeries.length > 0) {
          setTimeout(() => {
            setObj({
              surgeries,
            });
          }, 100);
        }
      }
      // setObj(Object.assign({}, obj, editedObj));
    }
  }, [editedObj]);
  useEffect(() => {
    if (updatedObj) {
      const u = updateObjectValue(obj, updatedObj);
      setTimeout(() => {
        setObj(u);
      }, 100);
    }
  }, [updatedObj]);
  const getModList = async () => {
    const { data } = await adminAPi.get("admin/surgery-modality-records-all");
    console.log("DATA SURGERY", data.data);
    setModList(data.data);
  };

  const addDiagnosisField = () => {
    setObj({
      ...obj,
      surgeries: [
        ...obj.surgeries,
        {
          surgery_date: null,
          modality: "",
          surgical_laterality: [],
        },
      ],
    });
  };
  const removeDiagnosisFields = (index) => {
    const rows = [...obj.surgeries];
    rows.splice(index, 1);
    setObj({ ...obj, surgeries: rows });
  };
  const handleChangeDiagnosis = (index, name, value) => {
    const list = [...obj.surgeries];
    list[index][name] = value;
    setObj({ ...obj, surgeries: list });
  };
  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);
  return (
    <>
      {" "}
      <Grid item xs={12} sm={12}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={1}>
            <Grid mb={1} item sm={4}>
              Surgery
            </Grid>
          </Grid>
          {obj.surgeries.map((data, index) => {
            const { surgery_date, modality, surgical_laterality } = data;

            return (
              <Grid container spacing={2} key={index}>
                <Grid item xs={6} md={3} mb={1}>
                  <FeDesktopDatePicker
                    label="Surgery Date"
                    inputFormat="DD/MM/YYYY"
                    name="surgery_date"
                    value={surgery_date}
                    //  disabled={editedObj}
                    onChange={(val) => {
                      handleChangeDiagnosis(
                        index,
                        "surgery_date",
                        dayjs(val).format("YYYY-MM-DD")
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={4} mb={1}>
                  <FeAutocomplete
                    options={modList.map((o) => o.name)}
                    id="Modality"
                    label="Modality"
                    value={modality}
                    //  disabled={editedObj}
                    onChange={(e, v) => {
                      handleChangeDiagnosis(index, "modality", v);
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <FeAutocomplete
                    multiple
                    disableCloseOnSelect
                    //  disabled={editedObj}
                    value={
                      surgical_laterality && surgical_laterality.length > 0
                        ? surgical_laterality.map((o) => o.value)
                        : []
                    }
                    onChange={(e, v) => {
                      handleChangeDiagnosis(
                        index,
                        "surgical_laterality",
                        v.map((o) => {
                          return { value: o };
                        })
                      );
                    }}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            //  disabled={editedObj}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      );
                    }}
                    options={latList.map((o) => o.value)}
                    id="Surgical Laterility"
                    label="Surgical Laterility"
                  />
                </Grid>
                <Grid item xs={1}>
                  {obj.surgeries.length !== 1 && (
                    <IconButton
                      aria-label="delete"
                      size="large"
                      color="error"
                      //  disabled={editedObj}
                      onClick={() => removeDiagnosisFields(index)}
                    >
                      <DeleteForeverIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="success"
              //  disabled={editedObj}
              onClick={addDiagnosisField}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SurgeryChemoDetails;
