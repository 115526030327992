import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Outlet } from "react-router-dom";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";

import AccountMenuDoctor from "./components/AccountMenu/AccountMenuDoctor";
import MenuAsideDoctor from "./components/MenuAside/MenuAsideDoctor";

/* function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
} */

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    [theme.breakpoints.up("sm")]: {
      position: "relative",
    },
    backgroundColor: theme.palette.sidebar.main,
    color: theme.palette.sidebar.contrastText,
    whiteSpace: "nowrap",
    borderRight: 0,
    width: drawerWidth,
    "& a": {
      color: theme.palette.sidebar.contrastText,
    },

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
      // width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: 0,
      },
    }),
  },
}));

function PatientLayout() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="absolute"
        open={open}
        color="sidebar"
        sx={{ boxShadow: "none" }}
      >
        <Toolbar
          sx={{
            pr: "24px",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={toggleDrawer}
              color="inherit"
              size="small"
              sx={{
                border: 1,
                borderColor: "rgba(255, 255, 255, 0.075)",
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          {/* <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Dashboard
          </Typography> */}
          <Box>
            <AccountMenuDoctor />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} color="sidebar">
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: [2],
            pr: [0],
          }}
        >
          <Typography
            variant="h6"
            textTransform="uppercase"
            fontWeight={600}
            sx={{ color: "#fff" }}
          >
            ONCOEDU
          </Typography>
          <IconButton
            color="inherit"
            size="small"
            onClick={toggleDrawer}
            sx={{ border: 1, borderColor: "rgba(255, 255, 255, 0.075)" }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.075)" }} />
        <Box p={2}>
          <MenuAsideDoctor />
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          // height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="false" sx={{ pt: 3 }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}

export default PatientLayout;
