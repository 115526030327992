import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { debounce, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import { FeAutocomplete } from "../../FormElements";
import FeTextField from "../../FormElements/FeTextField";

const TNMForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [formValue, setFormValue] = useState({
    t: null,
    n: null,
    m: null,
    result: null,
    type: "Clinical",
  });

  const [errors, setErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);
  function convertEmptyStringToNull(obj) {
    const newObj = {};

    for (let key in obj) {
      newObj[key] = obj[key] === "" ? null : obj[key];
    }

    return newObj;
  }
  async function getDetails(id) {
    const { data } = await adminAPi.get(
      `admin/staging-calculation-records/${id}`
    );
    setFormValue(data.data);
  }

  const handleField = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    const updatedData = convertEmptyStringToNull(formValue);
    e.preventDefault();
    try {
      setErrors({});
      setErrorMsg("");
      if (id) {
        console.log("AAAA", updatedData);
        const { data } = await adminAPi.put(
          `admin/staging-calculation-records/${id}`,
          updatedData
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      } else {
        console.log("AAAA", updatedData);
        const { data } = await adminAPi.post(
          "admin/staging-calculation-records",
          updatedData
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      }
    } catch ({ response }) {
      console.log(response);
      if (response) {
        const { data } = response;
        if (data && data.errors) {
          setErrors(data.errors);
        }

        if (data && data.message) {
          setErrorMsg(data.message);
        }
      }
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={4}>
            <FeTextField
              name="t"
              id="T"
              label="T"
              value={formValue.t ? formValue.t : ""}
              onChange={handleField}
            />
          </Grid>
          <Grid item sm={4}>
            <FeTextField
              name="n"
              id="N"
              label="N"
              value={formValue.n ? formValue.n : ""}
              onChange={handleField}
            />
          </Grid>
          <Grid item sm={4}>
            <FeTextField
              name="m"
              id="M"
              label="M"
              value={formValue.m ? formValue.m : ""}
              onChange={handleField}
            />
          </Grid>
          <Grid item sm={4}>
            <FeTextField
              name="result"
              id="result"
              label="Result"
              value={formValue.result ? formValue.result : ""}
              onChange={handleField}
            />
          </Grid>
          <Grid item sm={4}>
            <FeAutocomplete
              onChange={(e, v) => {
                // handleChangeInput("gender", v);
                setFormValue({ ...formValue, type: v });
              }}
              value={formValue.type ? formValue.type : ""}
              options={["Clinical", "Pathological"]}
              id="Sex"
              label="Type"
            />
          </Grid>
        </Grid>

        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection={"column"}
          mt={2}
        >
          {!isEmpty(errors) && (
            <>
              {Object.keys(errors).map((o, j) => {
                return (
                  <Typography
                    key={j}
                    variant="subtitle2"
                    gutterBottom
                    sx={{ color: "red" }}
                  >
                    {errors[o][0]}
                  </Typography>
                );
              })}
            </>
          )}
          {!isEmpty(errorMsg) && (
            <Typography variant="subtitle2" gutterBottom sx={{ color: "red" }}>
              {errorMsg}
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
export default TNMForm;
