import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import { FeAutocomplete } from "../../FormElements";
import FeTextField from "../../FormElements/FeTextField";

const records = ["Intent", "Modality", "Fraction", "Fraction Number"];
const RsrForm = () => {
  const { id } = useParams();
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [formValue, setFormValue] = useState({
    type: "Intent",
    value: "",
  });

  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);

  async function getDetails(id) {
    const { data } = await adminAPi.get(
      `admin/radiotherapy-schedule-records/${id}`
    );
    setFormValue(data.data);
  }

  const handleField = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBackendErrorMessage({});
    try {
      if (id) {
        const { data } = await adminAPi.put(
          `admin/radiotherapy-schedule-records/${id}`,
          formValue
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      } else {
        const { data } = await adminAPi.post(
          "admin/radiotherapy-schedule-records",
          formValue
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      }
    } catch (error) {
      console.log(error.response);
      if (error.response.status === 422) {
        setBackendErrorMessage(error.response.data.errors);
      }
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <FeAutocomplete
              name="type"
              id="type"
              label="Type"
              disableClearable
              value={formValue.type}
              options={records}
              onChange={(event, value) => {
                if (!value) {
                  return;
                }

                const name = "type";

                if (value) {
                  let e = { target: { name, value } };
                  handleField(e);
                }
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <FeTextField
              name="value"
              id="value"
              label="Value"
              value={formValue.value}
              onChange={(e) => {
                const inputValue = e.target.value;

                if (["Fraction", "Fraction Number"].includes(formValue.type)) {
                  // Allow only digits
                  if (!isNaN(inputValue) || inputValue === "") {
                    setFormValue({ ...formValue, value: inputValue });
                  }
                } else {
                  setFormValue({ ...formValue, value: inputValue });
                }
              }}
              inputProps={{
                type: ["Fraction", "Fraction Number"].includes(formValue.type)
                  ? "number"
                  : "text",
              }}
            />
          </Grid>
        </Grid>
        {Object.keys(backendErrorMessage).map((key) => (
          <p className="error-message">{backendErrorMessage[key]}</p>
        ))}
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
export default RsrForm;
