import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";

const FeAutocomplete = ({ label, disableCloseOnSelect, required, ...rest }) => {
  return (
    <Autocomplete
      disablePortal
      disableCloseOnSelect={disableCloseOnSelect}
      renderInput={(params) => (
        <TextField {...params} required={required} label={label} />
      )}
      {...rest}
    />
  );
};
export default FeAutocomplete;
