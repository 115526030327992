import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { NavLink } from "react-router-dom";
const MenuAsideAdmin = () => {
  return (
    <MenuList
      sx={{
        "& .MuiMenuItem-root": {
          p: 0,
          borderRadius: 1,
        },
        "& a": {
          display: "block",
          position: "relative",
          width: "100%",
          textDecoration: "none",
          px: 2,
          py: 1,
          color: "rgba(0, 0, 0, 0.87)",
          borderRadius: 1,
          overflow: "hidden",
          "&:before": {
            content: '""',
            display: "block",
            width: "4px",
            height: "100%",
            backgroundColor: "#205295",
            position: "absolute",
            left: 0,
            top: 0,
            opacity: 0,
          },
        },
        "& .active": {
          bgcolor: `rgba(255,255, 255, .075)`,
          color: "#fff",
          "&:before": {
            opacity: 1,
          },
        },
      }}
    >
      {/* <MenuItem>
        <NavLink to="/admin/patient-obs-records">
          Patient Observation Records
        </NavLink>
      </MenuItem> */}
      <MenuItem>
        <NavLink to="/admin/patient-observations">Patient Observations</NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink to="/admin/chemotherapy-modality-records">
          Treatment Modality Records
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/chemotherapy-protocol-records">
          Treatment Protocol Records
        </NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink to="/admin/comorbidity-records">Comorbidity Records</NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink to="/admin/centers">Centers</NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/charts">Charts</NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/cancer-marker-records">
          Cancer Marker Records
        </NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink to="/admin/doctors">Doctors</NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/doctor-recognition-records">
          Doctor Recognition Records
        </NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink to="/admin/diagnosis-disease-group-records">
          Diagnosis Disease Group Records
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/diagnosis-disease-subgroup-records">
          Diagnosis Disease Sub Group Records
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/diagnosis-laterility-records">
          Diagnosis Laterility Records
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/diagnosis-metastatic-site-records">
          Diagnosis Metastatic Site Records
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/diagnosis-primary-site-records">
          Diagnosis Primary Site Records
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/exon-records">Exon Records</NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink to="/admin/histopathology-records">
          Histopathology records
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/ihc-records">IHC records</NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/molecular-pathology-records">
          Molecular Pathology Records
        </NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink to="/admin/patients">Patients</NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink to="/admin/radiotherapy-schedule-records">
          Radiotherapy Schedule Records
        </NavLink>
      </MenuItem>
      {/* <MenuItem>
        <NavLink to="/admin/radiotherapy-schedule-intent-records">
          Radiotherapy Schedule Intent Records
        </NavLink>
      </MenuItem> */}
      <MenuItem>
        <NavLink to="/admin/response-rate-calculation-records">
          Response Rate Calculation Records
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/response-rate-records">
          Response Rate Records
        </NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink to="/admin/socio-economic-status-records">
          Socio economic status
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/staging-calculation-records">
          Staging Calculation Records
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/surgery-modality-records">
          Surgery Modality Records
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/admin/surgical-laterality-records">
          Surgical Laterality Records
        </NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink to="/admin/users">Users</NavLink>
      </MenuItem>

      {/* <MenuItem>
        <NavLink to="/admin/staging-clinical-records">
          Staging Clinical Records
        </NavLink>
      </MenuItem> */}
      {/* <MenuItem>
        <NavLink to="/admin/staging-pathological-records">
          Staging Pathological Records
        </NavLink>
      </MenuItem> */}
    </MenuList>
  );
};
export default MenuAsideAdmin;
